import React, { useContext } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Database } from './services/Database';
import { BsArrowClockwise, BsArrowLeftCircleFill, BsArrowRightCircleFill, BsArrowsExpandVertical, BsFillCaretLeftFill, BsFillCaretRightFill, BsFillXCircleFill, BsWechat, BsArrowsAngleExpand, BsArrowsAngleContract, BsPhoneLandscapeFill } from 'react-icons/bs';
import { Network } from './services/Network';
import { Account } from './services/Account';
import { PlayFab } from './services/PlayFab';
import './HostPage.css';
import { debounce } from 'lodash';
import SeasonPage from './SeasonPage';
import { HeaderContext } from './MainPage';

const YOUTUBE_CHANNELS = [
  { hostname: 'novarally', youtubeEmbedUrl: 'https://www.youtube.com/embed/live_stream?channel=UCHjBymVKgZLJRB_EtMcK3-A' }
];

let LOADOUT_VERSION = 1

interface HostPageProps {
  id: string;
  toggleHeader: () => void;
}

interface Command {
  id: string;
  type: string;
  emoji: string;
  status: 'pending' | 'active' | 'complete';
  timeLeft?: number;
  timestamp: number;
}

interface HostPageState {
  user: string;
  channel: string;
  mode: string;
  alert: string | null;
  loadout: any;
  collection: string;
  type: string;
  online: boolean;
  tag: string;
  showChat: boolean;
  inventory: any[]
  showSeasonDialog: boolean;
  chatMessages: Array<{user: string, message: string}>;
  isLandscape: boolean;
  commands: Command[];
}

interface Car {
  id: string;
  name: string;
  weight: number;
  health: number;
  speed: number;
  acceleration: number;
  handling: number;
}

interface Driver {
  id: string;
  name: string;
  speed: number;
  boost: number;
  shield: number;
  ram: number;
  brake: number;
  handling: number;
}

class HostPage extends React.Component<HostPageProps, HostPageState> {

  protected monitorTimer:any;
  protected lastActionTime:number;
  private videoRef = React.createRef<HTMLIFrameElement>();
  private chatInputRef = React.createRef<HTMLInputElement>();
  private chatMessagesRef = React.createRef<HTMLDivElement>();
  private commandTimer: any;

  constructor(props: HostPageProps) {
    super(props);
    this.state = {
      user: 'CryptoJack21',
      channel: props.id, // Set the channel immediately from the id prop
      mode: 'chat',
      alert: null,
      loadout: null,
      collection: '',
      type: '',
      online: false,
      tag: '',
      showChat: true,
      inventory: [],
      showSeasonDialog: false,
      chatMessages: [],
      isLandscape: false,
      commands: [],
    }
    this.lastActionTime = 0;
    console.log(`Channel set to: ${props.id}`); // Log the channel immediately
  }

  

  componentDidMount(): void {
    console.log('HostPage componentDidMount');
    
    if(Account.isLoggedIn()) {
      console.log('Account is logged in');
      this.connectToNetwork();
    } else {
      console.log('Account is not logged in');
    }

    let loadout = this.loadLoadout();
    this.setState({loadout});

    this.startMonitoringNetwork();

    this.loadInventory();

    Network.onMessage = this.handleNetworkMessage;
    this.startCommandTimer();
  }
  handleNetworkMessage = (message: any) => {
    let data = JSON.parse(message.data);
    console.log('Received network message:', data);
    if (data.id === 'chat-message') {
      this.handleChatMessage(data);
    }
    // Handle other message types as needed
  }

  async connectToNetwork() {
    const channel = this.state.channel.toLowerCase();
    const username = Account.getUserName();
    
    console.log('Connecting to network - Channel:', channel, 'Username:', username);
    
    if (channel && username) {
      console.log('Attempting to connect to network');
      await Network.connect();
      Network.registerUser(channel, username);
    } else {
      console.error('Unable to connect: missing channel or username');
      if (!channel) console.error('Channel is missing or undefined');
      if (!username) console.error('Username is missing or undefined');
    }
  }



  componentWillUnmount(): void {
    this.stopMonitoringNetwork();
    Network.disconnect();
    Network.onMessage = () => {}; 
    this.stopCommandTimer();
  }

  loadLoadout() {
    let loadout = {
      version: LOADOUT_VERSION,
      car: {
        id: Database.getRandomCar().id,
        booster: 'none',
        hood: 'none',
        door: 'none',
        emote: 'none',
        hat: 'none',
        horn: 'none',
        paint: 'none',
        ram:'none',
        trail: 'none',
        underglow: 'none'
      },
      driver: {
        id: Database.getRandomDriver().id,
        celebration: 'none',
        skin: 'none'
      },
      passenger: {
        id: Database.getRandomDriver().id,
        celebration: 'none',
        skin: 'none'
      }
    };

    let s = window.localStorage.getItem('Loadout');

    if(s) {
      let parsedLoadout = JSON.parse(s);
      if(parsedLoadout.version == LOADOUT_VERSION)
        loadout = parsedLoadout;
    }

    return loadout;
  }

  async loadInventory() {
    if(!Account.isLoggedIn())
      return;

    let inventory = await Account.getInventory(true);
    this.setState({inventory});
  }

  startMonitoringNetwork() {
    this.monitorTimer = setInterval(() => {
      let online = Network.isConnected();
      if(this.state.online != online)
        this.setState({online});
    }, 1000);
  }

  stopMonitoringNetwork() {
    clearInterval(this.monitorTimer);
  }

  sendActionEvent(action:string, data:any = null) {
    let event:any = {
      id: 'game-action', 
      channel: this.state.channel.toLowerCase(), 
      user: {
        id: Account.getUserId(),
        name: Account.getUserName()
      },
      action
    };

    if(data)
      event.data = data;

    Network.sendEvent(event);
  }

  saveLoadout() {
    let s = JSON.stringify(this.state.loadout);
    window.localStorage.setItem('Loadout', s);
    this.sendActionEvent('loadout', this.state.loadout);
  }

  onAction(action: string) {
    if(!Account.isLoggedIn()) {
      this.setState({alert: 'Please login to play.'});
      return;
    }

    if(!Network.isConnected()) {
      this.setState({alert: 'Not connected to the network!'});
      return;
    }

    // Add command to queue with proper typing
    const newCommand: Command = {
      id: Math.random().toString(36).substr(2, 9),
      type: action,
      emoji: this.getEmojiForAction(action),
      status: 'pending',
      timeLeft: 2,
      timestamp: Date.now()
    };

    this.setState(prevState => ({
      commands: [newCommand, ...prevState.commands].slice(0, 5)
    }));

    this.sendActionEvent(action);
  }

  onJoinRace() {
    if(!Account.isLoggedIn()) {
      this.setState({alert: 'Please login to play.'});
      return;
    }

    if(!Network.isConnected()) {
      this.setState({alert: 'Not connected to the network!'});
      return;
    }

    this.sendActionEvent('race', this.state.loadout);
  }

  onCustomizeSave() {
    this.saveLoadout();
    this.setState({mode: 'chat'})
  }

  onCustomizeClose() {
    let loadout = this.loadLoadout();
    this.setState({loadout, mode: 'chat'});    
  }

  onCarClick(id:string) {
    let loadout = {...this.state.loadout};
    loadout.car.id = id;
    this.setState({loadout, mode: 'customize-car'});
  }

  onCosmetic(collection:string, tag:string, type:string) {
    this.setState({mode: 'cosmetics', type, tag, collection})
  }

  onCosmeticClick(asset:string, collection:string, tags:string[]) {
    let loadout = {...this.state.loadout};

    if(collection == 'boosters') loadout.car.booster = asset;
    if(collection == 'decals' && tags.indexOf('hood') != -1) loadout.car.hood = asset;
    if(collection == 'decals' && tags.indexOf('door') != -1) loadout.car.door = asset;
    if(collection == 'emotes') loadout.car.emote = asset;
    if(collection == 'hats') loadout.car.hat = asset;
    if(collection == 'horns') loadout.car.horn = asset;
    if(collection == 'paint') loadout.car.paint = asset;
    if(collection == 'rams') loadout.car.ram = asset;
    if(collection == 'trails') loadout.car.trail = asset;
    if(collection == 'underglows') loadout.car.underglow = asset;
    if(collection == 'skins') loadout[this.state.type].skin = asset;
    if(collection == 'celebrations') loadout[this.state.type].celebration = asset;

    this.setState({loadout, mode: this.state.type == 'car' ? 'customize-car' : 'customize-drivers'});
  }


  onOpenSeasonDialog = () => {
    this.setState({ showSeasonDialog: true });
    this.getSeasonData();
  }

  onCloseSeasonDialog = () => {
    this.setState({ showSeasonDialog: false });
  }

  getSeasonData = async () => {
    try {
      const result = await PlayFab.executeCloudScript("getSeason", {});
      if (result.success) {
        console.log("Season data retrieved successfully:", result.data);
        // You can update the state here if needed
      } else {
        console.error("Failed to retrieve season data:", result);
      }
    } catch (error) {
      console.error('Error retrieving season data:', error);
    }
  }

  setMode(mode:string) {
    if(!Account.isLoggedIn()) {
      this.setState({alert: 'Please login to play.'});
      return;
    }

    if(!Network.isConnected()) {
      this.setState({alert: 'Not connected to the network!'});
      return;
    }

    this.setState({mode});
  }

  onNextCar() {
    let loadout = {...this.state.loadout};

    let ci = Database.getCarIndex(loadout.car.id);

    if(ci == -1) 
      loadout.car.id = Database.getFirstCar().id;
    else if(ci == Database.getTotalCars() - 1)
      loadout.car.id = 'random';
    else
      loadout.car.id = Database.getNextCar(loadout.car.id).id;

    this.setState({loadout});
  }

  onPreviousCar() {
    let loadout = {...this.state.loadout};

    let ci = Database.getCarIndex(loadout.car.id);

    if(ci == -1) 
      loadout.car.id = Database.getLastCar().id;
    else if(ci == 0)
      loadout.car.id = 'random';
    else
      loadout.car.id = Database.getPreviousCar(loadout.car.id).id;

    this.setState({loadout});
  }

  onNextDriver(type:string) {
    let loadout = {...this.state.loadout};

    let di = Database.getDriverIndex(loadout[type].id);

    if(di == -1) 
      loadout[type].id = Database.getFirstDriver().id;
    else if(di == Database.getTotalDrivers()-1)
      loadout[type].id = 'random';
    else
      loadout[type].id = Database.getNextDriver(loadout[type].id).id;

    this.setState({loadout});
  }

  onPreviousDriver(type:string) {
    let loadout = {...this.state.loadout};

    let di = Database.getDriverIndex(loadout[type].id);

    if(di == -1) 
      loadout[type].id = Database.getLastDriver().id;
    else if(di == 0)
      loadout[type].id = 'random';
    else
      loadout[type].id = Database.getPreviousDriver(loadout[type].id).id;

    this.setState({loadout});
  }

  onSelectDriver(type:string) {
    this.setState({type, mode: 'drivers'});
  }


  onDriverClick(id:string) {
    let loadout = {...this.state.loadout};
    loadout[this.state.type].id = id;
    this.setState({loadout, mode: 'customize-drivers'})
  }

  onSwap() {
    let loadout = {...this.state.loadout};
    let driver = loadout['driver'].id;
    loadout['driver'].id = loadout['passenger'].id;
    loadout['passenger'].id = driver;
    this.setState({loadout})
  }

  onToggleChat() {
    this.setState({showChat: !this.state.showChat});
  }

  renderVideo() {
    const videoClass = this.state.isLandscape ? 'host-page-landscape-video' : 'host-page-video';
    const youtubeChannel = YOUTUBE_CHANNELS.find(channel => channel.hostname.toLowerCase() === this.state.channel.toLowerCase());
  
    if (youtubeChannel) {
      return (
        <div className={videoClass}>
          <iframe
            ref={this.videoRef}
            src={youtubeChannel.youtubeEmbedUrl}
            frameBorder="0"
            allowFullScreen={true}
            height="100%"
            width="100%"
          />
        </div>
      );
    }
  
    return (
      <div className={videoClass}>
        <iframe
          ref={this.videoRef}
          src={`https://player.twitch.tv/?channel=${this.state.channel.toLowerCase()}&parent=${window.location.hostname}&low_latency=true`}
          frameBorder="0"
          allowFullScreen={true}
          scrolling="no"
          height="100%"
          width="100%"
        />
      </div>
    );
  }

  renderChat() {
    const isYoutubeChannel = YOUTUBE_CHANNELS.some(channel => channel.hostname.toLowerCase() === this.state.channel.toLowerCase());
    
    if (isYoutubeChannel) {
      return null; // Don't render chat for YouTube channels
    }
  
    let display = this.state.showChat ? 'block' : 'none';
  
    return (
      <iframe
        id="TwitchChat"
        className="host-page-chat"
        src={`https://www.twitch.tv/embed/${this.state.channel.toLowerCase()}/chat?parent=${window.location.hostname}`}
        frameBorder={0}
        allowFullScreen={true} 
        style={{display}}
      >
      </iframe>
    )
  }

  renderCarsDialog() {
    let divs = [];
    for(let i = 0; i < Database.cars.length; i++) {
      let car = Database.cars[i];
      let src = `/images/cars/${car.id}.png`;
      divs.push(
        <div key={i} className="flex-column" style={{rowGap: '5px', cursor: 'pointer'}} onClick={()=>this.onCarClick(car.id)}>
          <img style={{width: '100%'}} src={src} />
          <div style={{fontSize: '0.8em', textAlign: 'center'}}>{car.name}</div>
        </div>
      )
    }

    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered " style={{padding: '0px', maxHeight: '525px'}}>
          <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}>
            <div style={{width: '30px', paddingLeft: '10px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: 'customize-car'})}>
              <BsArrowLeftCircleFill />
            </div>
            <div style={{textAlign: 'center'}}>Select Car</div>
            <div style={{width: '30px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: 'chat'})}>
              <BsFillXCircleFill />
            </div>
          </div>
          <div className="host-page-modal-content">
            {divs}
          </div>
        </div>
      </div>
    )
  }

  renderDriversDialog() {
    let divs = [];
    for(let i = 0; i < Database.drivers.length; i++) {
      let driver = Database.drivers[i];
      let src = `/images/drivers/${driver.id}.png`;
      divs.push(
        <div key={i} className="flex-column" style={{rowGap: '5px', cursor: 'pointer'}} onClick={()=>this.onDriverClick(driver.id)}>
          <img style={{width: '100%', borderRadius: '3px'}} src={src} />
          <div style={{fontSize: '0.8em', textAlign: 'center'}}>{driver.nick ? driver.nick : driver.name}</div>
        </div>
      )
    }

    let title = 'Select Driver';
    if(this.state.mode == 'passengers')
      title = 'Select Passenger'

    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered" style={{padding: '0px', maxHeight: '600px'}}>
          <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}>
            <div style={{width: '30px', paddingLeft: '10px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: 'customize-drivers'})}>
              <BsArrowLeftCircleFill />
            </div>
            <div style={{textAlign: 'center'}}>{title}</div>
            <div style={{width: '30px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: 'chat'})}>
              <BsFillXCircleFill />
            </div>
          </div>
          <div className="host-page-modal-content">
            {divs}
          </div>
        </div>
      </div>
    )
  }

  refreshInventory = async () => {
    try {
      await Account.getInventory(true);
      this.forceUpdate();
    } catch (error) {
      console.error('Error refreshing inventory:', error);
    }
  }

  renderCosmeticsDialog() {
    let collection = Database.getCollection(this.state.collection)
    let assets = Database.getAssets(this.state.collection);

    let divs = [];

    divs.push(
      <div key={assets.length} className="flex-column" style={{rowGap: '5px', cursor: 'pointer'}} onClick={()=>this.onCosmeticClick('none', this.state.collection, [this.state.tag])}>
        <img style={{width: '100%', borderRadius: '5px'}} src="/images/none.png" />
        <div style={{fontSize: '0.8em', textAlign: 'center'}}>None</div>
      </div>
    )

    for(let i = 0; i < assets.length; i++) {
      let asset = assets[i];
      if(this.state.tag && asset.tags.indexOf(this.state.tag) == -1)
        continue;
      
      // Check if the asset is in the player's inventory
      let existing = this.state.inventory.find((a:any) => a.id == asset.id);
      
      // If it's not in the inventory and not in development mode, skip it
      if(!existing && (this.state.channel != 'evolverstudios') && (this.state.channel != 'turisstation'))
        continue;
      
      let src = `/images/assets/${this.state.collection}/${asset.image ? asset.image : asset.id}.png`;
      divs.push(
        <div key={i} className="flex-column" style={{rowGap: '5px', cursor: 'pointer'}} onClick={()=>this.onCosmeticClick(asset.id, asset.collection, asset.tags)}>
          <img style={{width: '100%', borderRadius: '5px'}} src={src} />
          <div style={{fontSize: '0.8em', textAlign: 'center'}}>{asset.name}</div>
        </div>
      )
    }

    let title = collection.name;
    
    if(collection.name.substring(collection.name.length-1) == 's')
      title = collection.name.substring(0, collection.name.length-1);

    let backMode = this.state.type == 'car' ? 'customize-car' : 'customize-drivers';

    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered" style={{padding: '0px', height: '525px'}}>
          <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{width: '30px', paddingLeft: '10px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: backMode})}>
                <BsArrowLeftCircleFill />
              </div>
              <div style={{width: '30px', display: 'flex', color: 'gray', cursor: 'pointer', marginLeft: '10px'}} onClick={() => this.loadInventory()}>
                <BsArrowClockwise />
              </div>
            </div>
            <div style={{textAlign: 'center'}}>Select {title}</div>
            <div style={{width: '30px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: 'chat'})}>
              <BsFillXCircleFill />
            </div>
          </div>
          <div className="host-page-modal-content">
            {divs}
          </div>
        </div>
      </div>
    )
  }

  renderStatBar(key:number, label:string, value:number) {
    let pips = [];
    for(let i = 0; i < 5; i++) {
      let color = i < value ? 'orange' : '#ffffff10';
      pips.push(
        <div key={i} style={{height: '10px', width: '100%', borderRadius: '5px', backgroundColor: color}} />
      )
    }

    let barWidth = `${value * 20}%`;
    let background = 'linear-gradient(197.78deg, #F1BD42 -12.54%, #EF8F49 104.72%)';
    return (
      <div key={key}>
        <div style={{color: 'gray'}}>{label}</div>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', columnGap: '5px', marginTop: '3px'}}>
          {pips}
        </div>

        {/* <div style={{backgroundColor: 'black', width: '100%', height: '10px', borderRadius: '5px', marginTop: '2px', position: 'relative'}}>
          <div style={{background, width: barWidth, height: '100%', borderRadius: '5px', position: 'absolute', top: '0', left: '0'}} />
        </div> */}
      </div>    
    )
  }

  renderDriverStatBar(key:number, label:string, driver:number, passenger:number) {
    let pips = [];
    let total = 0;
    for(let i = 0; i < 8; i++) {
      let color = i < driver + passenger ? total < driver ? 'orange' : 'green' : '#ffffff10';
      total++;
      pips.push(
        <div key={i} style={{height: '15px', width: '100%', borderRadius: '5px', backgroundColor: color}} />
      )
    }

    return (
      <div key={key} style={{display: 'grid', gridTemplateColumns: '80px 1fr', alignItems: 'center'}}>
        <div style={{fontSize: '0.9em', color: 'gray', textAlign: 'left'}}>{label}</div>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr', columnGap: '3px'}}>
          {pips}
        </div>
      </div>    
    )
  }

  renderAnalysisStatBar(key:number, label:string, values:number[]) {
    let pips = [];
    let total = 0;
    for(let i = 0; i < 8; i++) {
      let color = '';
      if(values[i] == 0)
        color = '#ffffff10';
      else if(values[i] <= 62)
        color = 'green';
      else if(values[i] <= 93)
        color = 'orange';
      else
        color = 'red';
      // else if(values > 20 && values < 50)
      //   color = 'yellow';
      // else if(values > 50 && values < 50)
      //   color = 'yellow';
      // else if(values > 20 && values < 50)
      //   color = 'yellow';
      total++;
      pips.push(
        <div key={i} style={{fontSize: '0.7em', color: 'black', height: '15px', width: '100%', borderRadius: '5px', backgroundColor: color}}>
          {/* {((values[i]/441)*100).toFixed(1)} */}
          {/* {((values[i]/441)*100).toFixed(0)}% */}
          {values[i]}
        </div>
      )
    }

    return (
      <div key={key} style={{display: 'grid', gridTemplateColumns: '80px 1fr', alignItems: 'center'}}>
        <div style={{fontSize: '0.9em', color: 'gray', textAlign: 'left'}}>{label}</div>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr', columnGap: '3px'}}>
          {pips}
        </div>
      </div>
    )    
  }

  renderCarPanel() {
    let car = null;
    let carIdx = Database.getTotalCars();
    let carImage = `/images/random-car.png`;
    let carName = 'Random';

    if(this.state.loadout.car.id != 'random') {
      car = Database.getCar(this.state.loadout.car.id);
      carIdx = Database.getCarIndex(this.state.loadout.car.id);
      carImage = car ? `/images/cars/${car.id}.png` : '/images/cars/default.png';
      carName = car ? car.name : 'Unknown Car';
    }
    
    return (
      <div className="flex-row" style={{backgroundColor: 'var(--background-color)', padding: '10px', borderRadius: '5px'}}>
        <div style={{display: 'grid', gridTemplateColumns: '150px 1fr', columnGap: '15px', width: '100%'}}>
          <div>
            <img style={{width: '100%', borderRadius: '5px', cursor: 'pointer'}} src={carImage} onClick={()=>this.setMode('cars')} />
            <div className="flex-row" style={{width: '100%', justifyContent: 'space-between', color: 'gray', alignItems: 'center'}}>
              <div style={{cursor: 'pointer', transform: 'translateY(3px)'}} onClick={()=>this.onPreviousCar()} >
                <BsArrowLeftCircleFill />
              </div>
              <div style={{color: 'white'}}>{carIdx+1}/{Database.getTotalCars()+1}</div>
              <div style={{cursor: 'pointer', transform: 'translateY(3px)'}} onClick={()=>this.onNextCar()} >
                <BsArrowRightCircleFill />
              </div>
            </div>
          </div>
          <div className="flex-column" style={{rowGap: '0px', textAlign: 'left'}}>
            <div>{carName}</div>
            <div className="flex-column" style={{color: 'gray', fontSize: '0.8em', rowGap: '5px', marginTop: '5px'}}>
              {this.renderStatBar(0, 'Weight', car ? car.weight : 0)}
              {this.renderStatBar(1, 'Health', car ? car.health : 0)}
              {this.renderStatBar(2, 'Speed', car ? car.speed : 0)}
              {this.renderStatBar(3, 'Accel', car ? car.acceleration : 0)}
              {this.renderStatBar(4, 'Handling', car ? car.handling : 0)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderCosmeticPanel(key:number, cosmetic:any, type:string) {
    let cosDiv = <div>{cosmetic.name}</div>
    if(cosmetic.asset != 'none') {
      let asset = Database.getAsset(cosmetic.asset);
      let src = `/images/assets/${asset.collection}/${asset.image ? asset.image : asset.id}.png`;
      cosDiv = <img style={{width: '100%', height: '100%', borderRadius: '5px'}} src={src} />;
    }

    return (
      <div key={key} className="host-page-car-cosmetic" onClick={()=>this.onCosmetic(cosmetic.collection, cosmetic.tag, type)}>
        {cosDiv}
      </div>
    )
  }

  renderCarCosmeticsPanel() {
    let loadout = this.state.loadout;

    let cosmetics = [
      {collection: 'paint', name: 'Paint', asset: loadout.car.paint},
      {collection: 'decals', name: 'Hood', tag: 'hood', asset: loadout.car.hood},
      {collection: 'decals', name: 'Door', tag: 'door', asset: loadout.car.door},
      {collection: 'trails', name: 'Trail', asset: loadout.car.trail},
      {collection: 'underglows', name: 'Glow', asset: loadout.car.underglow},
      {collection: 'emotes', name: 'Emote', asset: loadout.car.emote},
      {collection: 'boosters', name: 'Boost', asset: loadout.car.booster},
      {collection: 'rams', name: 'Ram', asset: loadout.car.ram},
      {collection: 'horns', name: 'Horn', asset: loadout.car.horn},
      {collection: 'hats', name: 'Hat', asset: loadout.car.hat}
    ]

    let divs = [];
    for(let i = 0; i < cosmetics.length; i++) 
      divs.push(this.renderCosmeticPanel(i, cosmetics[i], 'car'))
    
    return (
      <div className="flex-row" style={{backgroundColor: 'var(--background-color)', padding: '10px', borderRadius: '5px'}}>
        <div className="flex-row" style={{flexWrap: 'wrap', rowGap: '5px', columnGap: '5px', width: '100%'}}>
          {divs}
        </div>
      </div>
    )
  }

  renderCustomizeCarDialog() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered customize-car-dialog" style={{maxWidth: '400px', padding: '0px', height: '525px'}}>
          <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}>
            <div style={{width: '30px', paddingLeft: '10px', display: 'flex', color: 'gray', cursor: 'pointer'}}>
              &nbsp;
            </div>
            <div style={{textAlign: 'center'}}>Customize Car</div>
            <div style={{width: '30px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.onCustomizeClose()}>
              <BsFillXCircleFill />
            </div>
          </div>
          <div className="flex-column" style={{rowGap: '10px', margin: '10px'}}>
            {this.renderCarPanel()}
            {this.renderCarCosmeticsPanel()}
            <div><button style={{width: '120px', margin: '5px 0px 5px 0px'}} onClick={()=>this.onCustomizeSave()}>Save</button></div>
          </div>
        </div>
      </div>
    );
  }

  renderDriverPanel(type:string) {
    let driverId = this.state.loadout[type].id;

    let driver = null;
    let driverIdx = Database.getTotalDrivers();
    let driverImage = `/images/random-driver.png`;
    let driverName = 'Random';

    if(driverId != 'random') {
      driver = Database.getDriver(driverId);
      driverIdx = Database.getDriverIndex(driverId);
      driverImage = `/images/drivers/${driverId}.png`;
      driverName = driver.name;
    }

    let celebration = 'None';
    if(this.state.loadout[type].celebration != 'none') {
      let asset = Database.getAsset(this.state.loadout[type].celebration);
      celebration = asset.name;
    }

  return (
    <div className="driver-panel flex-column" style={{rowGap: '5px'}}>
      <img 
        className="driver-image"
        style={{
          width: '100%', 
          maxWidth: '150px', 
          borderRadius: '5px', 
          cursor: 'pointer'
        }} 
        src={driverImage} 
        onClick={()=>this.onSelectDriver(type)} 
      />
      <div>{driverName}</div>
      <div className="flex-row" style={{margin: 'auto', width: '85%', justifyContent: 'space-between', color: 'gray', alignItems: 'center'}}>
        <div style={{cursor: 'pointer', transform: 'translateY(3px)'}} onClick={()=>this.onPreviousDriver(type)} >
          <BsArrowLeftCircleFill />
        </div>
        <div style={{fontSize: '0.9em'}}>{driverIdx+1}/{Database.getTotalDrivers()+1}</div>
        <div style={{cursor: 'pointer', transform: 'translateY(3px)'}} onClick={()=>this.onNextDriver(type)} >
          <BsArrowRightCircleFill />
        </div>
      </div>
      <div className="host-page-celebration-button" onClick={()=>this.onCosmetic('celebrations', '', type)}>
        <div className="subtext" style={{fontSize: '0.8em'}}>Celebration</div>
        <div>{celebration}</div>
      </div>
    </div>
  )
}

renderCustomizeDriversDialog() {
  let driverId = this.state.loadout['driver'].id;
  let driver = Database.getDriver(driverId);

  let passengerId = this.state.loadout['passenger'].id;
  let passenger = Database.getDriver(passengerId);

  let statDivs = [];

  statDivs.push(this.renderDriverStatBar(5, 'Actions', driver ? Math.ceil(driver.actions * 0.5) : 0, passenger ? passenger.actions : 0));
  statDivs.push(this.renderDriverStatBar(1, 'Boosting', driver ? driver.boosting : 0, passenger ? Math.ceil(passenger.boosting * 0.5) : 0));
  statDivs.push(this.renderDriverStatBar(0, 'Driving', driver ? driver.driving : 0, passenger ? Math.ceil(passenger.driving * 0.5) : 0));
  statDivs.push(this.renderDriverStatBar(2, 'Morale', driver ? Math.ceil(driver.morale * 0.5) : 0, passenger ? passenger.morale : 0));
  statDivs.push(this.renderDriverStatBar(3, 'Ramming', driver ? driver.ramming : 0, passenger ? Math.ceil(passenger.ramming * 0.5) : 0));
  statDivs.push(this.renderDriverStatBar(4, 'Shielding', driver ? Math.ceil(driver.shielding * 0.5) : 0, passenger ? passenger.shielding : 0));

  return (
    <div className="dialog-cover">
      <div className="dialog-panel-centered customize-driver-dialog" style={{maxWidth: '400px', width: '90vw', maxHeight: '90vh', padding: '10px', overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px'
        }}>
          <div style={{width: '30px'}}></div>
          <div>Customize Driver & Passenger</div>
          <div style={{width: '30px', cursor: 'pointer'}} onClick={() => this.onCustomizeClose()}>
            <BsFillXCircleFill />
          </div>
        </div>
        <div style={{
          flexGrow: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-around',
            gap: '10px',
            backgroundColor: 'var(--background-color)',
            padding: '10px',
            borderRadius: '5px'
          }}>
            <div style={{flex: '1'}}>
              {this.renderDriverPanel('driver')}
            </div>
            <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={() => this.onSwap()}>
              <BsArrowsExpandVertical style={{fontSize: '1.5em'}} />
            </div>
            <div style={{flex: '1'}}>
              {this.renderDriverPanel('passenger')}
            </div>
          </div>
          <div className="driver-stats" style={{
            backgroundColor: 'var(--background-color)',
            padding: '10px',
            borderRadius: '5px',
          }}>
            {statDivs}
          </div>
        </div>
        <div style={{marginTop: '10px', textAlign: 'center'}}>
          <button style={{width: '120px'}} onClick={() => this.onCustomizeSave()}>Save</button>
        </div>
      </div>
    </div>
  );
}

  renderSeasonDialog() {
    if (!this.state.showSeasonDialog) return null;

    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered" style={{width: '90%', maxWidth: '800px', height: '90%', padding: '20px'}}>
          <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
            <h2>Season Information</h2>
            <div style={{cursor: 'pointer'}} onClick={this.onCloseSeasonDialog}>
              <BsFillXCircleFill />
            </div>
          </div>
          <div style={{height: 'calc(100% - 60px)', overflowY: 'auto'}}>
            <SeasonPage />
          </div>
        </div>
      </div>
    );
  }

  renderButtons() {
    return (
      <div className="new-host-buttons">
        {/* Left column - Game Actions */}
        <div>
          <div className="new-host-game-actions">
            <button className="new-host-button" onClick={()=>this.onAction('boost')}>
              <span className="corner-emoji">🗲</span>
              🚀<br/>Boost
            </button>
            <button className="new-host-button" onClick={()=>this.onAction('shield')}>
              <span className="corner-emoji">🗲</span>
              🛡️<br/>Shield
            </button>
            <button className="new-host-button" onClick={()=>this.onAction('ram')}>
              <span className="corner-emoji">🗲</span>
              🔰<br/>Ram
            </button>
            <button className="new-host-button" onClick={()=>this.onAction('brake')}>
              <span className="corner-emoji">🗲</span>
              🛑<br/>Brake
            </button>
          </div>
          <div className="new-host-special-actions">
            <button className="new-host-button" onClick={()=>this.onAction('special-driver')}>
              <span className="corner-emoji">🗲</span>
              🌟<br/>Driver
            </button>
            <button className="new-host-button" onClick={()=>this.onAction('special-passenger')}>
              <span className="corner-emoji">🗲</span>
              ✨<br/>Passenger
            </button>
            <button className="new-host-button" onClick={()=>this.onAction('emote')}>
              😐<br/>Emote
            </button>
            <button className="new-host-button" onClick={()=>this.onAction('horn')}>
              🎺<br/>Horn
            </button>
          </div>
        </div>

        {/* Right column - Menu Controls */}
        <div className="new-host-menu-controls">
          <button className="new-host-button primary" onClick={()=>this.onJoinRace()}>
            🏁 Join Race
          </button>
          <button className="new-host-button" onClick={()=>this.setMode('customize-car')}>
            🚗 Car
          </button>
          <button className="new-host-button" onClick={()=>this.setMode('customize-drivers')}>
            🧑 Drivers
          </button>
          <button className="new-host-button" onClick={this.onOpenSeasonDialog}>
            🏆 Season
          </button>
        </div>

        {/* Status Indicators */}
        <div className="host-page-network" style={{backgroundColor: this.state.online ? 'green' : 'red'}} />
        <div className="host-page-chat-button" onClick={()=>this.onToggleChat()}>
          {this.state.showChat ? 
            <BsFillCaretRightFill style={{transform: 'translateY(-3px)'}} /> : 
            <BsFillCaretLeftFill style={{transform: 'translateY(-3px)'}} /> 
          }
          <BsWechat style={{fontSize: '1.5em'}} />
        </div>
      </div>
    )
  }

  onActivateRemoteClip = async () => {
    if(!Account.isLoggedIn()) {
      this.setState({alert: 'Please login to activate a remote clip.'});
      return;
    }

    if(!Network.isConnected()) {
      this.setState({alert: 'Not connected to the network!'});
      return;
    }

    console.log("Current PlayFab player ID:", PlayFab.getPlayerId());

    const isYoutubeChannel = YOUTUBE_CHANNELS.some(channel => channel.hostname.toLowerCase() === this.state.channel.toLowerCase());
    const params = {
      playfabUserId: PlayFab.getPlayerId(),
      livestreamTimestamp: Date.now().toString(),
      livestreamType: isYoutubeChannel ? 'youtube' : 'twitch',
      livestreamChannel: this.state.channel.toLowerCase()
    };

    this.setState({alert: 'Activating remote clip...'});
    try {
      const result = await PlayFab.executeCloudScript("activateRemoteClip", params);
      console.log("Full cloud script result:", result);

      if (result.success) {
        console.log("Remote clip activated successfully:", result.data);
        this.setState({alert: 'Clip created and added to profile!'});
      } else {
        console.error("Failed to activate remote clip:", result);
        this.setState({alert: 'Failed to create clip. Please try again.'});
      }
    } catch (error) {
      console.error('Error activating remote clip:', error);
      this.setState({alert: 'An unexpected error occurred. Please try again later.'});
    }
  }

  sendChatMessage = () => {
    const message = this.chatInputRef.current?.value.trim();
    if (message && Network.isConnected()) {
      const event = {
        id: 'chat-message',
        channel: this.state.channel.toLowerCase(),
        user: {
          name: Account.getUserName()
        },
        message: message
      };
      Network.sendEvent(event);
      if (this.chatInputRef.current) {
        this.chatInputRef.current.value = '';
      }
    }
  }

  handleChatKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      this.sendChatMessage();
    }
  }

  handleChatMessage = (event: any) => {
    console.log('Received chat message:', event);
    const { user, message } = event;
    
    this.setState(prevState => ({
      chatMessages: [
        ...prevState.chatMessages,
        { user: user.name, message }
      ]
    }), () => {
      console.log('Updated chat messages:', this.state.chatMessages);
      if (this.chatMessagesRef.current) {
        this.chatMessagesRef.current.scrollTop = this.chatMessagesRef.current.scrollHeight;
      }
    });
  }

  handleNetworkEvent = (event: any) => {
    if (event.id === 'chat-message') {
      this.handleChatMessage(event);
    }
    // Handle other event types as needed
  }

  renderChatBox() {
    return (
      <div className="host-page-chatbox">
        <div className="chat-messages" ref={this.chatMessagesRef}>
          {this.state.chatMessages.map((msg, index) => (
            <div key={index} className="chat-message">
              <span className="chat-username">{msg.user}: </span>
              <span className="chat-text">{msg.message}</span>
            </div>
          ))}
        </div>
        <div className="chat-input">
          <input
            type="text"
            ref={this.chatInputRef}
            placeholder="Type a message..."
            onKeyPress={this.handleChatKeyPress}
          />
          <button onClick={this.sendChatMessage}>Send</button>
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <div className="page">
        <div className="flex-column">
          {this.renderVideo()}
          {this.renderButtons()}
          {this.renderChat()}
        </div>
      </div>
    )
  }

  renderAlertDialog() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel" style={{width: '250px'}}>
          <div>{this.state.alert}</div>
          <div>
            <button style={{marginTop: '25px'}} onClick={()=>this.setState({alert: ''})}>OK</button>
          </div>
        </div>
      </div>
    )
  }

  toggleLandscapeMode = () => {
    this.setState(prevState => ({ isLandscape: !prevState.isLandscape }));
    this.props.toggleHeader();
  }

  renderLandscapeButton() {
    return (
      <div 
        className="host-page-landscape-button" 
        onClick={this.toggleLandscapeMode}
      >
        <BsPhoneLandscapeFill />
      </div>
    );
  }

  renderLandscapeLeftButtons() {
    return (
      <div className="host-page-landscape-buttons">
        <button onClick={() => this.onJoinRace()}>🏁<br/>Join</button>
        <button onClick={() => this.setMode('customize-car')}>🚗<br/>Car</button>
        <button onClick={() => this.setMode('customize-drivers')}>🧑<br/>Drivers</button>
        <button onClick={this.onOpenSeasonDialog}>🏆<br/>Season</button>
        <button onClick={() => this.onAction('emote')}>😐<br/>Emote</button>
        <button onClick={() => this.onAction('horn')}>🎺<br/>Horn</button>
      </div>
    );
  }
  
  renderLandscapeRightButtons() {
    return (
      <div className="host-page-landscape-buttons">
        <button onClick={() => this.onAction('boost')}>🚀<br/>Boost</button>
        <button onClick={() => this.onAction('ram')}>🔰<br/>Ram</button>
        <button onClick={() => this.onAction('brake')}>🛑<br/>Brake</button>
        <button onClick={() => this.onAction('shield')}>🛡️<br/>Shield</button>
        <button onClick={() => this.onAction('special-driver')}>🌟<br/>Driver<br/>Special</button>
        <button onClick={() => this.onAction('special-passenger')}>✨<br/>Passenger<br/>Special</button>
      </div>
    );
  }

  renderLandscapeLayout() {
    return (
      <div className="host-page-landscape">
        <div className="host-page-landscape-left-controls">
          {this.renderLandscapeLeftButtons()}
        </div>
        <div className="host-page-landscape-video">
          {this.renderVideo()}
          {this.renderCommandTimeline()}
        </div>
        <div className="host-page-landscape-right-controls">
          {this.renderLandscapeRightButtons()}
        </div>
      </div>
    );
  }

  startCommandTimer() {
    this.commandTimer = setInterval(() => {
      this.updateCommands();
    }, 100);
  }

  stopCommandTimer() {
    if (this.commandTimer) {
      clearInterval(this.commandTimer);
    }
  }

  updateCommands() {
    const now = Date.now();
    this.setState(prevState => {
      const updatedCommands = prevState.commands
        .map(cmd => {
          const age = now - cmd.timestamp;
          if (age > 5000) return { ...cmd, status: 'complete' as const };
          if (age > 2000) return { ...cmd, status: 'active' as const };
          return { 
            ...cmd, 
            status: 'pending' as const,
            timeLeft: Math.ceil((2000 - age) / 1000)
          };
        })
        .filter(cmd => now - cmd.timestamp < 4000);

      return { commands: updatedCommands };
    });
  }

  getEmojiForAction(action: string): string {
    const emojiMap: {[key: string]: string} = {
      'boost': '🚀',
      'brake': '🛑',
      'ram': '🔰',
      'shield': '🛡️',
      'special-driver': '🌟',
      'special-passenger': '✨',
      'emote': '😐',
      'horn': '🎺'
    };
    return emojiMap[action] || '❓';
  }

  renderCommandTimeline() {

    return (
      <>
        <div className="timeline-header">
          <span className="emoji">📻</span>
          <span>Driver Radio</span>
        </div>
        <div className="timeline-track">
          {this.state.commands.slice(0, 8).reverse().map((cmd) => {
            const now = Date.now();
            const timeLeft = Math.max(0, Math.ceil((3000 - (now - cmd.timestamp)) / 1000));
            const age = now - cmd.timestamp;
            const isExecuting = age >= 3000 && age < 4000;
            const isRemoving = age > 4000;

            return (
              <div
                key={cmd.id}
                className={`command-item ${isExecuting ? 'executing' : ''} ${isRemoving ? 'removing' : ''}`}
              >
                <div className="command-content">
                  <span className="emoji">{cmd.emoji}</span>
                </div>
                {timeLeft > 0 && <span className="command-timer">{timeLeft}</span>}
                <div className="progress" />
                {isExecuting && (
                  <div className="command-heard">
                    <span className="icon">👂</span>
                    <span className="tick">✓</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </>
    );
  }

  render() {
    if(this.state.channel == '')
      return null;

    return (
      <div className="new-host-page-container">
        <div className="new-host-main-row">
          <div className="new-host-buttons">
            {this.renderButtons()}
          </div>
          <div className="new-host-video">
            {this.renderVideo()}
          </div>
          <div className="new-host-twitch-chat">
            {this.renderChat()}
          </div>
        </div>
        <div className="new-host-timeline-row">
          <div className="command-timeline">
            {this.renderCommandTimeline()}
          </div>
        </div>
        <div className="host-page-chatbox">
          {this.renderChatBox()}
        </div>
        {this.renderLandscapeButton()}
          {this.state.mode == 'customize-car' && this.renderCustomizeCarDialog()}
          {this.state.mode == 'customize-drivers' && this.renderCustomizeDriversDialog()}
          {this.state.mode == 'cars' && this.renderCarsDialog()}
          {this.state.mode == 'drivers' && this.renderDriversDialog()}
          {this.state.mode == 'passengers' && this.renderDriversDialog()}
          {this.state.mode == 'cosmetics' && this.renderCosmeticsDialog()}
          {this.state.alert != '' && this.renderAlertDialog()}
          {this.renderSeasonDialog()}
      </div>
    )
  }

  onSelectCar(id: string) {
    this.setState({
      loadout: {
        ...this.state.loadout,
        car: {
          ...this.state.loadout.car,
          id
        }
      }
    }, () => this.setMode('customize-car'));
  }


  onSelectCosmetic(id: string) {
    let loadout = {...this.state.loadout};
    if(this.state.type == 'car') {
      if(this.state.tag)
        loadout.car[this.state.collection + '_' + this.state.tag] = id;
      else
        loadout.car[this.state.collection] = id;
    } else {
      loadout[this.state.type][this.state.collection] = id;
    }
    
    this.setState({
      loadout,
      mode: this.state.type == 'car' ? 'customize-car' : 'customize-drivers'
    });
  }
}


function HostPageWrapper() {
  const { id } = useParams<{ id: string }>();
  const headerContext = useContext(HeaderContext);

  console.log("HostPageWrapper rendering, headerContext:", headerContext);

  if (!headerContext) {
    console.error("HeaderContext is undefined");
    return <div>Error: HeaderContext is undefined</div>;
  }

  return <HostPage id={id || ''} toggleHeader={headerContext.toggleHeader} />;
}

export default HostPage;
export { HostPageWrapper };