import React, { useState, useEffect } from 'react';
import { Account } from './services/Account';
import { PlayFab } from './services/PlayFab';
import { Database } from './services/Database';
import './DriftPage.css';
import { DriftMainMenu } from './components/DriftMainMenu';

interface Car {
    id: string;
    name: string;
    server: number;
    image: string;
    weight: number;
    health: number;
    speed: number;
    acceleration: number;
    handling: number;
}

interface Driver {
    id: string;
    name: string;
    server: number;
    image: string;
    driving: number;
    boosting: number;
    actions: number;
    morale: number;
    ramming: number;
    shielding: number;
    ability: string;
}

interface CrewMember {
    id: string;
    name: string;
    ability: string;
    image: string;
    server: number;
}

interface DriftSelection {
  cars: Car[];
  drivers: Driver[];
  passengers: Driver[];
  pitCrew: CrewMember[];
}

interface DriftPageState {
  mode: 'lobby' | 'selection' | 'race' | 'main-menu' | 'upgrade' | 'recruit';
  selections?: DriftSelection;
  loading: boolean;
  error?: string;
  loadout: {
    car: Car;
    driver: Driver;
    passenger: Driver;
    pitCrew: CrewMember;
  };
  selectedCar: Car | undefined;
  selectedDriver: Driver | null;
  selectedPassenger: Driver | null;
  selectedPitCrew: CrewMember | null;
  driftData?: {
    car: number;
    driver: number;
    passenger: number;
    crew: number;
    health: number;
    budget: number;
    racesRemaining: number;
  };
  leagueId: string;
}

export const DriftPage: React.FC = () => {
  const [state, setState] = useState<DriftPageState>({
    mode: 'lobby',
    loading: true,
    selections: undefined,
    loadout: {
      car: {} as Car,
      driver: {} as Driver,
      passenger: {} as Driver,
      pitCrew: {} as CrewMember
    },
    selectedCar: undefined,
    selectedDriver: null,
    selectedPassenger: null,
    selectedPitCrew: null,
    leagueId: ''
  });

  const [leagueId, setLeagueId] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    checkExistingDrift();
  }, []);

  useEffect(() => {
    console.log('State updated:', {
      mode: state.mode,
      loading: state.loading,
      hasSelections: !!state.selections,
      selectionCounts: state.selections ? {
        cars: state.selections.cars.length,
        drivers: state.selections.drivers.length,
        passengers: state.selections.passengers.length,
        pitCrew: state.selections.pitCrew.length,
      } : null
    });
  }, [state]);

  useEffect(() => {
    if (state.selections) {
      const selections = state.selections;
      setState(prev => ({
        ...prev,
        selectedCar: selections.cars[0] || undefined,
        selectedDriver: selections.drivers[0] || null,
        selectedPassenger: selections.passengers[0] || null,
        selectedPitCrew: selections.pitCrew[0] || null
      }));
    }
  }, [state.selections]);

  const checkExistingDrift = async () => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const result = await PlayFab.executeCloudScript('getDriftData');

      if (result.data?.data?.car !== undefined) {
        setState(prev => ({
          ...prev,
          mode: 'main-menu',
          driftData: result.data.data,
          loading: false
        }));
      } else {
        setState(prev => ({ ...prev, loading: false }));
      }
    } catch (error) {
      console.error('Error checking drift data:', error);
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const handleStartDrift = async () => {
    if (!leagueId.trim()) {
      setError('Please enter a league ID');
      return;
    }

    setState(prev => ({ ...prev, loading: true, error: undefined }));

    try {
      const result = await PlayFab.executeCloudScript('getDriftSelections', {
        leagueId: leagueId.trim()
      });

      if (result.data?.selections?.cars) {
        const selectionData = result.data.selections;
        
        // Convert server IDs to actual objects
        const selections = {
          cars: selectionData.cars.map((id: number) => Database.getDriftCarByServerId(id)),
          drivers: selectionData.drivers.map((id: number) => Database.getDriftDriverByServerId(id)),
          passengers: selectionData.passengers.map((id: number) => Database.getDriftDriverByServerId(id)),
          pitCrew: selectionData.pitCrew.map((id: number) => Database.getDriftCrewByServerId(id))
        };

        // Set state with selections and initial choices
        setState(prev => ({
          ...prev,
          mode: 'selection',
          selections: selections,
          selectedCar: selections.cars[0],
          selectedDriver: selections.drivers[0],
          selectedPassenger: selections.passengers[0],
          selectedPitCrew: selections.pitCrew[0],
          loading: false,
          leagueId: leagueId.trim()
        }));
      } else {
        setState(prev => ({
          ...prev,
          loading: false,
          error: result.data?.error || 'Failed to get valid selections'
        }));
      }
    } catch (error) {
      console.error('Error starting drift:', error);
      setState(prev => ({
        ...prev,
        loading: false,
        error: 'Failed to start drift'
      }));
    }
  };

  const handleConfirmSelection = async () => {
    if (!state.selectedCar || !state.selectedDriver || !state.selectedPassenger || !state.selectedPitCrew) {
        return;
    }

    setState(prev => ({ ...prev, loading: true }));

    try {
        // Debug logs
        console.log('Selected Car:', state.selectedCar);
        console.log('Selected Driver:', state.selectedDriver);
        console.log('Selected Passenger:', state.selectedPassenger);
        console.log('Selected Crew:', state.selectedPitCrew);

        const result = await PlayFab.executeCloudScript('saveDriftData', {
            data: {
                car: state.selectedCar.server,
                driver: state.selectedDriver.server,
                passenger: state.selectedPassenger.server,
                crew: state.selectedPitCrew.server
            }
        });

        console.log('Save Result:', result);

        if (result.data?.FunctionResult?.success) {
            setState(prev => ({
                ...prev,
                mode: 'main-menu',
                driftData: result.data.FunctionResult.data,
                loading: false
            }));
        } else {
            setState(prev => ({
                ...prev,
                loading: false,
                error: 'Failed to save selections'
            }));
        }
    } catch (error) {
        console.error('Error saving selections:', error);
        setState(prev => ({
            ...prev,
            loading: false,
            error: 'Failed to save selections'
        }));
    }
  };

  const handlePreviousCar = () => {
    if (!state.selections?.cars.length) return;
    const currentIndex = state.selections.cars.findIndex(car => car.id === state.selectedCar?.id);
    const newIndex = currentIndex <= 0 ? state.selections.cars.length - 1 : currentIndex - 1;
    setState(prev => ({ ...prev, selectedCar: state.selections!.cars[newIndex] }));
  };

  const handleNextCar = () => {
    if (!state.selections?.cars.length) return;
    const currentIndex = state.selections.cars.findIndex(car => car.id === state.selectedCar?.id);
    const newIndex = currentIndex === -1 || currentIndex === state.selections.cars.length - 1 ? 0 : currentIndex + 1;
    setState(prev => ({ ...prev, selectedCar: state.selections!.cars[newIndex] }));
  };

  const handlePreviousDriver = () => {
    if (!state.selections?.drivers.length) return;
    const currentIndex = state.selections.drivers.findIndex(driver => driver.id === state.selectedDriver?.id);
    const newIndex = currentIndex <= 0 ? state.selections.drivers.length - 1 : currentIndex - 1;
    setState(prev => ({ ...prev, selectedDriver: state.selections!.drivers[newIndex] }));
  };

  const handleNextDriver = () => {
    if (!state.selections?.drivers.length) return;
    const currentIndex = state.selections.drivers.findIndex(driver => driver.id === state.selectedDriver?.id);
    const newIndex = currentIndex === -1 || currentIndex === state.selections.drivers.length - 1 ? 0 : currentIndex + 1;
    setState(prev => ({ ...prev, selectedDriver: state.selections!.drivers[newIndex] }));
  };

  const handlePreviousPassenger = () => {
    if (!state.selections?.passengers.length) return;
    const currentIndex = state.selections.passengers.findIndex(passenger => passenger.id === state.selectedPassenger?.id);
    const newIndex = currentIndex <= 0 ? state.selections.passengers.length - 1 : currentIndex - 1;
    setState(prev => ({ ...prev, selectedPassenger: state.selections!.passengers[newIndex] }));
  };

  const handleNextPassenger = () => {
    if (!state.selections?.passengers.length) return;
    const currentIndex = state.selections.passengers.findIndex(passenger => passenger.id === state.selectedPassenger?.id);
    const newIndex = currentIndex === -1 || currentIndex === state.selections.passengers.length - 1 ? 0 : currentIndex + 1;
    setState(prev => ({ ...prev, selectedPassenger: state.selections!.passengers[newIndex] }));
  };

  const handlePreviousCrew = () => {
    if (!state.selections?.pitCrew.length) return;
    const currentIndex = state.selections.pitCrew.findIndex(crew => crew.id === state.selectedPitCrew?.id);
    const newIndex = currentIndex <= 0 ? state.selections.pitCrew.length - 1 : currentIndex - 1;
    setState(prev => ({ ...prev, selectedPitCrew: state.selections!.pitCrew[newIndex] }));
  };

  const handleNextCrew = () => {
    if (!state.selections?.pitCrew.length) return;
    const currentIndex = state.selections.pitCrew.findIndex(crew => crew.id === state.selectedPitCrew?.id);
    const newIndex = currentIndex === -1 || currentIndex === state.selections.pitCrew.length - 1 ? 0 : currentIndex + 1;
    setState(prev => ({ ...prev, selectedPitCrew: state.selections!.pitCrew[newIndex] }));
  };

  const getCombinedStats = () => {
    if (!state.selectedDriver || !state.selectedPassenger) return null;

    return {
      driving: Math.min(8, state.selectedDriver.driving + Math.floor(state.selectedPassenger.driving / 2)),
      boosting: Math.min(8, state.selectedDriver.boosting + Math.floor(state.selectedPassenger.boosting / 2)),
      actions: Math.min(8, state.selectedDriver.actions + Math.floor(state.selectedPassenger.actions / 2)),
      morale: Math.min(8, state.selectedDriver.morale + Math.floor(state.selectedPassenger.morale / 2)),
      ramming: Math.min(8, state.selectedDriver.ramming + Math.floor(state.selectedPassenger.ramming / 2)),
      shielding: Math.min(8, state.selectedDriver.shielding + Math.floor(state.selectedPassenger.shielding / 2))
    };
  };

  const getAbilityIcon = (ability?: string) => {
    if (!ability) {
      return '/images/icons/SpecialIcons/beerdrop.webp';
    }
    return `/images/abilities/${ability}.png`;
  };

  if (state.loading) {
    return (
      <div className="drift-page">
        <h1>Drift Mode</h1>
        <div>Loading...</div>
      </div>
    );
  }

  if (state.mode === 'main-menu' && state.driftData) {
    return (
      <DriftMainMenu 
        driftData={state.driftData}
        onUpgradeCar={() => setState(prev => ({ ...prev, mode: 'upgrade' }))}
        onRecruitCrew={() => setState(prev => ({ ...prev, mode: 'recruit' }))}
        onRevive={checkExistingDrift}
      />
    );
  }

  if (state.mode === 'selection' && state.selections) {
    return (
      <div className="drift-page">
        <h1>Drift Mode Selection</h1>
        
        <div className="drift-selection-panel">
          <div className="drift-selections">
            {/* Car Selection */}
            <div className="drift-character-card">
              <h3>Car: {state.selectedCar?.name}</h3>
              <div className="drift-car-image">
                {state.selectedCar && (
                  <img src={state.selectedCar.image || `/images/cars/${state.selectedCar.id}.png`} alt={state.selectedCar.name} />
                )}
              </div>
              <div className="drift-navigation-buttons">
                <button onClick={() => handlePreviousCar()}>Previous</button>
                <button onClick={() => handleNextCar()}>Next</button>
              </div>
            </div>

            {/* Driver Selection */}
            <div className="drift-character-card">
              <h3>Driver: {state.selectedDriver?.name}</h3>
              <div className="drift-driver-image">
                {state.selectedDriver && (
                  <img src={state.selectedDriver.image || `/images/drivers/${state.selectedDriver.id}.png`} alt={state.selectedDriver.name} />
                )}
              </div>
              <div className="drift-ability">
                <img 
                  src={getAbilityIcon(state.selectedDriver?.ability)} 
                  alt={state.selectedDriver?.ability || 'Beer Drop'} 
                />
                <span>{state.selectedDriver?.ability || 'Beer Drop'}</span>
              </div>
              <div className="drift-navigation-buttons">
                <button onClick={() => handlePreviousDriver()}>Previous</button>
                <button onClick={() => handleNextDriver()}>Next</button>
              </div>
            </div>

            {/* Passenger Selection */}
            <div className="drift-character-card">
              <h3>Passenger: {state.selectedPassenger?.name}</h3>
              <div className="drift-driver-image">
                {state.selectedPassenger && (
                  <img src={state.selectedPassenger.image || `/images/drivers/${state.selectedPassenger.id}.png`} alt={state.selectedPassenger.name} />
                )}
              </div>
              <div className="drift-ability">
                <img 
                  src={getAbilityIcon(state.selectedPassenger?.ability)} 
                  alt={state.selectedPassenger?.ability || 'Beer Drop'} 
                />
                <span>{state.selectedPassenger?.ability || 'Beer Drop'}</span>
              </div>
              <div className="drift-navigation-buttons">
                <button onClick={() => handlePreviousPassenger()}>Previous</button>
                <button onClick={() => handleNextPassenger()}>Next</button>
              </div>
            </div>

            {/* Pit Crew Selection */}
            <div className="drift-character-card">
              <h3>Pit Crew: {state.selectedPitCrew?.name}</h3>
              <div className="drift-driver-image">
                {state.selectedPitCrew && (
                  <img 
                    src={state.selectedPitCrew.image || `/images/crew/${state.selectedPitCrew.id}.png`} 
                    alt={state.selectedPitCrew.name}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.onerror = null;
                      target.src = '/images/crew/default.png';
                    }}
                  />
                )}
              </div>
              <div className="drift-ability">
                <img 
                  src={getAbilityIcon(state.selectedPitCrew?.ability)} 
                  alt={state.selectedPitCrew?.ability || 'Beer Drop'} 
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.onerror = null;
                    target.src = '/images/icons/SpecialIcons/beerdrop.webp';
                  }}
                />
                <span>{state.selectedPitCrew?.ability || 'Beer Drop'}</span>
              </div>
              <div className="drift-navigation-buttons">
                <button onClick={() => handlePreviousCrew()}>Previous</button>
                <button onClick={() => handleNextCrew()}>Next</button>
              </div>
            </div>
          </div>

          <div className="drift-stats-area">
            <h2>Stats</h2>
            <div className="drift-stats-grid">
              {/* Left column - Car stats */}
              <div>
                <div className="drift-stat-bar">
                  <div className="drift-stat-label">Weight</div>
                  <div className="drift-stat-pips">
                    {state.selectedCar && typeof state.selectedCar.weight === 'number' && (
                      [...Array(5)].map((_, i) => (
                        <div key={i} className={`drift-stat-pip ${i < state.selectedCar!.weight ? 'filled' : ''}`} />
                      ))
                    )}
                  </div>
                </div>
                <div className="drift-stat-bar">
                  <div className="drift-stat-label">Health</div>
                  <div className="drift-stat-pips">
                    {state.selectedCar && typeof state.selectedCar.health === 'number' && (
                      [...Array(5)].map((_, i) => (
                        <div key={i} className={`drift-stat-pip ${i < state.selectedCar!.health ? 'filled' : ''}`} />
                      ))
                    )}
                  </div>
                </div>
                <div className="drift-stat-bar">
                  <div className="drift-stat-label">Speed</div>
                  <div className="drift-stat-pips">
                    {state.selectedCar && typeof state.selectedCar.speed === 'number' && (
                      [...Array(5)].map((_, i) => (
                        <div key={i} className={`drift-stat-pip ${i < state.selectedCar!.speed ? 'filled' : ''}`} />
                      ))
                    )}
                  </div>
                </div>
                <div className="drift-stat-bar">
                  <div className="drift-stat-label">Accel</div>
                  <div className="drift-stat-pips">
                    {state.selectedCar && typeof state.selectedCar.acceleration === 'number' && (
                      [...Array(5)].map((_, i) => (
                        <div key={i} className={`drift-stat-pip ${i < state.selectedCar!.acceleration ? 'filled' : ''}`} />
                      ))
                    )}
                  </div>
                </div>
                <div className="drift-stat-bar">
                  <div className="drift-stat-label">Handling</div>
                  <div className="drift-stat-pips">
                    {state.selectedCar && typeof state.selectedCar.handling === 'number' && (
                      [...Array(5)].map((_, i) => (
                        <div key={i} className={`drift-stat-pip ${i < state.selectedCar!.handling ? 'filled' : ''}`} />
                      ))
                    )}
                  </div>
                </div>
              </div>

              {/* Right column - Combined Driver & Passenger stats */}
              <div>
                <div className="drift-stat-bar">
                  <div className="drift-stat-label">Driving</div>
                  <div className="drift-stat-pips">
                    {state.selectedDriver && state.selectedPassenger && (
                      [...Array(8)].map((_, i) => {
                        const combinedStats = getCombinedStats();
                        const baseValue = state.selectedDriver!.driving;
                        const combinedValue = combinedStats?.driving || 0;
                        return (
                          <div 
                            key={i} 
                            className={`drift-stat-pip ${i < baseValue ? 'filled' : ''} ${i >= baseValue && i < combinedValue ? 'boosted' : ''}`}
                          />
                        );
                      })
                    )}
                  </div>
                </div>
                <div className="drift-stat-bar">
                  <div className="drift-stat-label">Boosting</div>
                  <div className="drift-stat-pips">
                    {state.selectedDriver && state.selectedPassenger && (
                      [...Array(8)].map((_, i) => {
                        const combinedStats = getCombinedStats();
                        const baseValue = state.selectedDriver!.boosting;
                        const combinedValue = combinedStats?.boosting || 0;
                        return (
                          <div 
                            key={i} 
                            className={`drift-stat-pip ${i < baseValue ? 'filled' : ''} ${i >= baseValue && i < combinedValue ? 'boosted' : ''}`}
                          />
                        );
                      })
                    )}
                  </div>
                </div>
                <div className="drift-stat-bar">
                  <div className="drift-stat-label">Actions</div>
                  <div className="drift-stat-pips">
                    {state.selectedDriver && state.selectedPassenger && (
                      [...Array(8)].map((_, i) => {
                        const combinedStats = getCombinedStats();
                        const baseValue = state.selectedDriver!.actions;
                        const combinedValue = combinedStats?.actions || 0;
                        return (
                          <div 
                            key={i} 
                            className={`drift-stat-pip ${i < baseValue ? 'filled' : ''} ${i >= baseValue && i < combinedValue ? 'boosted' : ''}`}
                          />
                        );
                      })
                    )}
                  </div>
                </div>
                <div className="drift-stat-bar">
                  <div className="drift-stat-label">Morale</div>
                  <div className="drift-stat-pips">
                    {state.selectedDriver && state.selectedPassenger && (
                      [...Array(8)].map((_, i) => {
                        const combinedStats = getCombinedStats();
                        const baseValue = state.selectedDriver!.morale;
                        const combinedValue = combinedStats?.morale || 0;
                        return (
                          <div 
                            key={i} 
                            className={`drift-stat-pip ${i < baseValue ? 'filled' : ''} ${i >= baseValue && i < combinedValue ? 'boosted' : ''}`}
                          />
                        );
                      })
                    )}
                  </div>
                </div>
                <div className="drift-stat-bar">
                  <div className="drift-stat-label">Ramming</div>
                  <div className="drift-stat-pips">
                    {state.selectedDriver && state.selectedPassenger && (
                      [...Array(8)].map((_, i) => {
                        const combinedStats = getCombinedStats();
                        const baseValue = state.selectedDriver!.ramming;
                        const combinedValue = combinedStats?.ramming || 0;
                        return (
                          <div 
                            key={i} 
                            className={`drift-stat-pip ${i < baseValue ? 'filled' : ''} ${i >= baseValue && i < combinedValue ? 'boosted' : ''}`}
                          />
                        );
                      })
                    )}
                  </div>
                </div>
                <div className="drift-stat-bar">
                  <div className="drift-stat-label">Shielding</div>
                  <div className="drift-stat-pips">
                    {state.selectedDriver && state.selectedPassenger && (
                      [...Array(8)].map((_, i) => {
                        const combinedStats = getCombinedStats();
                        const baseValue = state.selectedDriver!.shielding;
                        const combinedValue = combinedStats?.shielding || 0;
                        return (
                          <div 
                            key={i} 
                            className={`drift-stat-pip ${i < baseValue ? 'filled' : ''} ${i >= baseValue && i < combinedValue ? 'boosted' : ''}`}
                          />
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button 
            className="drift-start-race-button"
            disabled={!state.selectedCar || !state.selectedDriver || !state.selectedPassenger || !state.selectedPitCrew}
            onClick={handleConfirmSelection}
          >
            Lock In Team
          </button>
        </div>
      </div>
    );
  }

  if (state.mode === 'lobby') {
    return (
      <div className="drift-page">
        <h1>Drift Mode</h1>
        <>
          <div className="league-input">
            <input
              type="text"
              value={leagueId}
              onChange={(e) => setLeagueId(e.target.value)}
              placeholder="Enter League ID (e.g., drift01)"
            />
            {error && <div className="error-message">{error}</div>}
          </div>

          <button onClick={handleStartDrift}>Start New Drift</button>
        </>
      </div>
    );
  }

  return null;
};

export default DriftPage;