import { Database } from "./Database";
import { Network } from "./Network";
import { Twitch } from "./Twitch";
import { PlayFab, InventoryResponse } from './PlayFab';
import { Google } from './Google';

export class Account {
  protected static userId: string = '';
  protected static userName: string = '';
  protected static displayName: string = '';
  protected static userPortrait: string = '';
  protected static loggedIn: boolean = false;
  protected static inventory: any[] = [];
  protected static inventoryLoaded: boolean = false;
  protected static callbacks: any = {};
  protected static platformUserId: string = '';
  protected static season: any = null;
  protected static seasonTime: number = 0;
  private static driftData: any = null;

  public static async loginWithCustomId(id: string) {
    let response = await PlayFab.post('LoginWithCustomID', {
      TitleId: 'D93AF',
      CustomId: id,
      CreateAccount: true
    });

    return { success: (response.status == 200), body: response.body };
  }

  public static async loginWithEmailAddress(email: string, password: string) {
    let response = await PlayFab.post('LoginWithEmailAddress', {
      TitleId: 'D93AF',
      Email: email,
      Password: password,
      RequireBothUsernameAndEmail: false
    });

    return { success: (response.status == 200), body: response.body };
  }

  public static async loginWithTwitch() {
    Twitch.login();
  }

  public static async continueLoginWithTwitch(accessToken: string) {
    let response = await PlayFab.loginWithTwitch(accessToken);

    if (response.success) {
      Twitch.setAccessToken(accessToken);
      let userResponse = await Twitch.getUser();
      if (userResponse.success)
        await Account.finishLoginWithTwitch(accessToken, userResponse);
    }

    return { success: response.success };
  }

  public static async finishLoginWithTwitch(accessToken: string, userResponse: any) {
    Account.userId = PlayFab.getPlayerId();
    Account.userName = userResponse.body.data[0].login;
    Account.displayName = await Account.getOrCreateUsername(userResponse.body.data[0].id, userResponse.body.data[0].display_name);
    Account.userPortrait = userResponse.body.data[0].profile_image_url;
    Account.platformUserId = userResponse.body.data[0].id;
    Account.loggedIn = true;

    let cache = {
      platform: 'twitch',
      user: userResponse.body.data[0].id,
      token: accessToken
    };

    localStorage.setItem('AutoLogin', JSON.stringify(cache));

    await Account.finishLogin();
  }

  public static async loginWithGoogle() {
    Google.login();
  }

  public static async continueLoginWithGoogle(idToken: string) {
    let response = await PlayFab.loginWithOpenIdConnect(idToken, 'Google');

    if (response.success) {
      Google.setIdToken(idToken);
      let userResponse = await Google.getUser();
      if (userResponse.success)
        await Account.finishLoginWithGoogle(idToken, userResponse);
    }

    return { success: response.success };
  }

  public static async finishLoginWithGoogle(idToken: string, userResponse: any) {
    Account.userId = PlayFab.getPlayerId();
    Account.userName = await Account.getOrCreateUsername(userResponse.body.sub, userResponse.body.name);
    Account.userPortrait = userResponse.body.picture;
    Account.platformUserId = userResponse.body.sub;
    Account.loggedIn = true;

    let cache = {
      platform: 'google',
      user: userResponse.body.sub,
      token: idToken
    };

    localStorage.setItem('AutoLogin', JSON.stringify(cache));

    await Account.finishLogin();
  }

  public static async continueLoginWithAuth0(idToken: string, userInfo: any) {
    console.log("Auth0 userInfo:", userInfo);
    try {
      const response = await PlayFab.loginWithOpenIdConnect(idToken, 'Auth0');
      console.log("PlayFab loginWithOpenIdConnect response:", response);
      
      if (response.success) {
        Account.userId = PlayFab.getPlayerId();
        const generatedUsername = await Account.getOrCreateUsername(userInfo.sub, userInfo.nickname);
        Account.userName = generatedUsername;
        Account.displayName = generatedUsername;
        Account.userPortrait = userInfo.picture;
        Account.platformUserId = userInfo.sub;
        Account.loggedIn = true;

        // Update avatar URL in PlayFab
        if (Account.userPortrait) {
          const avatarUpdateSuccess = await PlayFab.updateAvatarUrl(Account.userPortrait);
          if (avatarUpdateSuccess) {
            console.log("Avatar URL updated successfully in PlayFab");
          } else {
            console.error("Failed to update avatar URL in PlayFab");
          }
        }

        let cache = {
          platform: 'auth0',
          user: userInfo.sub,
          token: idToken
        };

        localStorage.setItem('AutoLogin', JSON.stringify(cache));

        await Account.finishLogin();
        return { success: true };
      } else {
        console.error('Failed to login with PlayFab:', response);
        return { success: false, error: 'Failed to login with PlayFab' };
      }
    } catch (error: unknown) {
      console.error('Error during Auth0 login:', error);
      if (error instanceof Error) {
        return { success: false, error: error.message };
      } else {
        return { success: false, error: 'An unknown error occurred' };
      }
    }
  }

  public static async getInventory(force: boolean = false) {
    if (!force && Account.inventoryLoaded)
      return Account.inventory;

    Account.inventory = [];
    Account.inventoryLoaded = true;

    let allItems: any[] = [];
    let continuationToken: string | null = null;

    do {
      const response: InventoryResponse = await PlayFab.getInventory(continuationToken);

      if (response.success && response.items) {
        allItems = allItems.concat(response.items);
        continuationToken = response.continuationToken || null;
      } else {
        console.error("Failed to fetch inventory");
        return Account.inventory;
      }
    } while (continuationToken);

    for (let i = 0; i < allItems.length; i++) {
      let serverId = allItems[i].Id;
      let amount = allItems[i].Amount;
      let asset = Database.getAssetByServerId(serverId);
      if (asset)
        Account.inventory.push({ id: asset.id, amount })
    }

    return Account.inventory;
  }

  public static addAssetToInventoryCache(id: string, amount: number) {
    if (!Account.inventoryLoaded)
      return;

    let existing = Account.inventory.find((a: any) => a.id == id);
    if (existing)
      existing.amount += amount;
    else
      Account.inventory.push({ id, amount });
  }

  public static hasAutoLogin() {
    let item = localStorage.getItem('AutoLogin');
    return item != null;
  }

  // public static async autoLogin() {
  //   let item = localStorage.getItem('AutoLogin');
  //   if (!item)
  //     return { success: false };

  //   let cache = JSON.parse(item);
  //   let token = cache.token;

  //   if (cache.platform == 'twitch') {
  //     Twitch.setAccessToken(token);
  //     let userResponse = await Twitch.getUser();
  //     if (userResponse.success) {
  //       let response = await PlayFab.loginWithTwitch(token);
  //       if (response.success) {
  //         await Account.finishLoginWithTwitch(token, userResponse);
  //         return response;
  //       }
  //     }
  //   } else if (cache.platform == 'google') {
  //     Google.setIdToken(token);
  //     let userResponse = await Google.getUser();
  //     if (userResponse.success) {
  //       let response = await PlayFab.loginWithOpenIdConnect(token, 'GoogleOpenId');
  //       if (response.success) {
  //         await Account.finishLoginWithGoogle(token, userResponse);
  //         return response;
  //       }
  //     }
  //   } else if (cache.platform == 'auth0') {
  //     // try {
  //     //   console.log("Attempting Auth0 auto-login...");
  //     //   let response = await PlayFab.loginWithOpenIdConnect(token, 'Auth0');
  //     //   console.log("Auth0 auto-login PlayFab response:", response);
  //     //   if (response.success) {
  //     //     await Account.continueLoginWithAuth0(token, { sub: cache.user });
  //     //     return response;
  //     //   }
  //     // } catch (error) {
  //     //   console.error('Error during Auth0 auto-login:', error);
  //     // }
  //   }

  //   localStorage.removeItem('AutoLogin');
  //   return { success: false };
  // }

  public static logout() {
    // Clear account state
    Account.userId = '';
    Account.userName = '';
    Account.displayName = '';
    Account.userPortrait = '';
    Account.platformUserId = '';
    Account.loggedIn = false;
    Account.inventory = [];
    Account.inventoryLoaded = false;
    Account.season = null;
    Account.seasonTime = 0;

    // Clear local storage
    localStorage.removeItem('AutoLogin');

    // Make callbacks to notify components of the logout
    Account.makeCallbacks();

    // Clear PlayFab session
    PlayFab.clearSession();
  }

  public static async registerUser(email: string, password: string) {
    let response = await PlayFab.post('RegisterPlayFabUser', {
      TitleId: 'D93AF',
      Email: email,
      Password: password,
      RequireBothUsernameAndEmail: false
    });

    return { success: (response.status == 200), body: response.body };
  }

  public static isLoggedIn(): boolean {
    return Account.loggedIn || PlayFab.hasValidSession();
  }

  public static getUserId(): string {
    return Account.userId || PlayFab.getPlayerId();
  }

  public static getPlatformUserId(): string {
    return Account.platformUserId;
  }

  public static getUserName(): string {
    return Account.displayName || Account.userName;
  }

  public static getUserPortrait(): string {
    return Account.userPortrait;
  }

  public static addCallback(id: string, callback: Function) {
    Account.callbacks[id] = callback;
  }

  public static removeCallback(id: string) {
    delete Account.callbacks[id];
  }

  protected static makeCallbacks() {
    let ids = Object.keys(Account.callbacks);
    for (let i = 0; i < ids.length; i++) {
      let callback = Account.callbacks[ids[i]];
      if (callback)
        callback();
    }
  }

  public static async loadSeason() {
    let elapsed = performance.now() - Account.seasonTime;
    if (elapsed < (5 * 60 * 1000))
      return;

    let response = await PlayFab.executeCloudScript('getSeason');

    if (response.success) {
      Account.season = response.data.season;
      Account.seasonTime = performance.now();
    }
  }

  public static getSeason() {
    return Account.season;
  }

  public static getSeasonPlayerData() {
    if (!Account.season) return { level: 0, current: 0, max: 0 };
    let xpPerLevel = Account.season.levels[0];
    let level = Math.floor(Account.season.xp / xpPerLevel);
    let current = Account.season.xp - (level * xpPerLevel);
    let max = xpPerLevel;
    return { level, current, max }
  }

  public static getSeasonQuests() {
    return Account.season ? Account.season.quests : [];
  }

  public static getSeasonMilestones() {
    return Account.season ? Account.season.milestones : [];
  }

  public static getSeasonRewards() {
    return Account.season ? Account.season.rewards : [];
  }

  public static async claimQuestReward(qi: number) {
    let response = await PlayFab.executeCloudScript('claimQuestReward', { quest: qi });
    if (response.success) {
      Account.season.xp = response.data.xp;
      Account.season.quests[qi].claimed = true;
      if (qi != 0)
        Account.season.quests[0].progress++;
    }
    return { success: response.success };
  }

  public static async claimMilestoneReward(mi: number) {
    let response = await PlayFab.executeCloudScript('claimMilestoneReward', { milestone: mi });
    if (response.success) {
      Account.season.xp = response.data.xp;
      Account.season.milestones[mi].level++;
      if (response.data.goal)
        Account.season.milestones[mi].goal = response.data.goal;
    }
    return { success: response.success };
  }

  public static async claimSeasonReward(level: number) {
    let response = await PlayFab.executeCloudScript('claimSeasonReward', { level });
    let success = response.success && response.data && response.data.success;
    if (success)
      Account.season.rewards[level].claimed = true;
    return { success };
  }

  public static isDeveloperLoggedIn(): boolean {
    let developers = [
      'cryptojack21',
      'sammysnake7',
      'sixbitglitch',
      'turisstation',
      'superdopetv',
      'reverendtate',
      'rob_boffin',
      'beanpole1904',
      'gizmogorilla',
      'sammysnake',
      // 'raks_wax',
      // 'dobermann_twitch'
    ];

    return Account.isLoggedIn() && developers.indexOf(Account.getUserName().toLowerCase()) !== -1;
  }

  private static async getOrCreateUsername(platformId: string, platformName?: string): Promise<string> {
    try {
      console.log(`Starting getOrCreateUsername for platformId: ${platformId}, platformName: ${platformName}`);

      const accountInfoResponse = await PlayFab.getAccountInfo();
      if (accountInfoResponse.status === 200 &&
        accountInfoResponse.body?.data?.AccountInfo?.TitleInfo?.DisplayName) {
        const existingDisplayName = accountInfoResponse.body.data.AccountInfo.TitleInfo.DisplayName;
        console.log("Existing TitleDisplayName found:", existingDisplayName);
        return existingDisplayName;
      }

      console.log("No existing TitleDisplayName found. Using platform name or generating new username...");
      
      // Extract connection from platformId (userInfo.sub)
      const parts = platformId.split('|');
      const connection = parts[0] === 'oauth2' ? parts[1] : parts[0];
      console.log("Extracted connection:", connection);
      
      let newUsername: string;
      switch (connection) {
        case 'google-oauth2':
        case 'twitch':
          console.log("Generating random username for:", connection);
          newUsername = Account.generateRandomUsername();
          break;
        default:
          console.log("Using default case with platformName:", platformName);
          newUsername = platformName || Account.generateRandomUsername();
      }
      
      console.log("Initial new username:", newUsername);

      console.log("Updating TitleDisplayName in PlayFab...");
      const updateResponse = await PlayFab.post('Client/UpdateUserTitleDisplayName', { DisplayName: newUsername });
      console.log("Update TitleDisplayName response:", updateResponse);

      if (updateResponse.status === 200) {
        const updatedDisplayName = updateResponse.body.data.DisplayName;
        console.log("TitleDisplayName successfully updated to:", updatedDisplayName);
        return updatedDisplayName;
      } else {
        console.error("Failed to update TitleDisplayName:", updateResponse);
        throw new Error("Failed to update TitleDisplayName");
      }
    } catch (error) {
      console.error("Error in getOrCreateUsername:", error);
      if (platformName) {
        console.log("Falling back to original platform name:", platformName);
        return platformName;
      }
      throw error;
    }
  }

  private static generateRandomUsername(): string {
    const adjectives = ['Happy', 'Lucky', 'Sunny', 'Clever', 'Swift', 'Brave', 'Bright'];
    const nouns = ['Panda', 'Tiger', 'Eagle', 'Dolphin', 'Fox', 'Wolf', 'Bear'];
    const randomNumber = Math.floor(Math.random() * 1000);

    const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];

    return `${randomAdjective}${randomNoun}${randomNumber}`;
  }

  public static async updateDisplayName(newDisplayName: string): Promise<boolean> {
    try {
      console.log("Updating display name to:", newDisplayName);
      const updateResponse = await PlayFab.post('Client/UpdateUserTitleDisplayName', { DisplayName: newDisplayName });
      console.log("Update display name response:", updateResponse);
  
      if (updateResponse.status === 200) {
        Account.displayName = newDisplayName;
        Account.makeCallbacks();
        return true;
      } else {
        console.error("Failed to update display name:", updateResponse);
        return false;
      }
    } catch (error) {
      console.error("Error updating display name:", error);
      return false;
    }
  }
  
    public static async finishLogin() {
      const accountInfoResponse = await PlayFab.getAccountInfo();
      
      if (accountInfoResponse.status === 200 && 
          accountInfoResponse.body?.data?.AccountInfo?.TitleInfo?.DisplayName) {
        const displayName = accountInfoResponse.body.data.AccountInfo.TitleInfo.DisplayName;
        Account.displayName = displayName;
        Account.userName = displayName; // Set userName as a fallback
      }
  
      let response = await PlayFab.executeCloudScript('getSeason');
      if (response.success) {
        Account.season = response.data.season;
        Account.seasonTime = performance.now();
      }
  
      Account.makeCallbacks();
    }

  public static async loadDrift() {
    try {
      const result = await PlayFab.executeCloudScript('getDriftData');
      if (result.success) {
        this.driftData = result.data?.data;
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error loading drift data:', error);
      return false;
    }
  }

  public static getDriftData() {
    return this.driftData;
  }

  public static async saveDriftData(data: any) {
    try {
      const result = await PlayFab.executeCloudScript('saveDriftData', { data });
      if (result.success) {
        this.driftData = data;
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error saving drift data:', error);
      return false;
    }
  }

  static async getCurrencies(): Promise<{ [key: string]: number }> {
    const response = await PlayFab.getCurrencyItems();
    if (response.success && response.items) {
      return response.items.reduce((acc: { [key: string]: number }, item: any) => {
        acc[item.Id] = item.Amount;
        return acc;
      }, {});
    }
    return {};
  }
}