import React from 'react';
import './CarCosmeticsPanel.css';

interface CosmeticItem {
  collection: string;
  name: string;
  tag?: string;
  asset: string;
}

interface CarCosmeticsPanelProps {
  loadout: any;
  onCosmetic: (collection: string, tag: string, type: string) => void;
}

export const CarCosmeticsPanel: React.FC<CarCosmeticsPanelProps> = ({
  loadout,
  onCosmetic
}) => {
  const cosmetics: CosmeticItem[] = [
    {collection: 'paint', name: 'Paint', asset: loadout.car.paint},
    {collection: 'decals', name: 'Hood', tag: 'hood', asset: loadout.car.hood},
    {collection: 'decals', name: 'Door', tag: 'door', asset: loadout.car.door},
    {collection: 'trails', name: 'Trail', asset: loadout.car.trail},
    {collection: 'underglows', name: 'Glow', asset: loadout.car.underglow},
    {collection: 'emotes', name: 'Emote', asset: loadout.car.emote},
    {collection: 'boosters', name: 'Boost', asset: loadout.car.booster},
    {collection: 'rams', name: 'Ram', asset: loadout.car.ram},
    {collection: 'horns', name: 'Horn', asset: loadout.car.horn},
    {collection: 'hats', name: 'Hat', asset: loadout.car.hat}
  ];

  const renderCosmeticItem = (cosmetic: CosmeticItem, index: number) => {
    let content = <div>{cosmetic.name}</div>;
    if (cosmetic.asset !== 'none') {
      const src = `/images/assets/${cosmetic.collection}/${cosmetic.asset}.png`;
      content = <img src={src} alt={cosmetic.name} />;
    }

    return (
      <div 
        key={index}
        className="cosmetic-item"
        onClick={() => onCosmetic(cosmetic.collection, cosmetic.tag || '', 'car')}
      >
        {content}
      </div>
    );
  };

  return (
    <div className="car-cosmetics-panel">
      {cosmetics.map((cosmetic, index) => renderCosmeticItem(cosmetic, index))}
    </div>
  );
}; 