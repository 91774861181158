import React from 'react';
import './AlertDialog.css';

interface AlertDialogProps {
  message: string;
  onClose: () => void;
}

export const AlertDialog: React.FC<AlertDialogProps> = ({ message, onClose }) => {
  return (
    <div className="dialog-cover">
      <div className="dialog-panel alert-dialog">
        <div className="alert-message">{message}</div>
        <button onClick={onClose}>OK</button>
      </div>
    </div>
  );
}; 