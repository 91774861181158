import React from 'react';
import { Database } from './services/Database';
import { BsArrowClockwise, BsArrowLeftCircleFill, BsArrowRightCircleFill, BsArrowsExpandVertical, BsFillCaretLeftFill, BsFillCaretRightFill, BsFillXCircleFill, BsWechat } from 'react-icons/bs';
import { Network } from './services/Network';
import { Account } from './services/Account';
import { PlayFab } from './services/PlayFab';
import './HostPage.css';
import { debounce } from 'lodash';
import SeasonPage from './SeasonPage';
import { Races } from './services/Races';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { BsTrophy, BsLightning, BsShield, BsBullseye, BsExclamationTriangle, BsFlagFill } from 'react-icons/bs';
import './Lobby.css';



const YOUTUBE_CHANNELS = [
  { hostname: 'novarally', youtubeEmbedUrl: 'https://www.youtube.com/embed/live_stream?channel=UCHjBymVKgZLJRB_EtMcK3-A' }
];

let LOADOUT_VERSION = 1

interface LobbyPageProps {
  lobbyName: string;
  league?: string;
}

interface LobbyPageState {
  user: string;
  channel: string;
  mode: string;
  alert: string;
  loadout: any;
  collection: string;
  type: string;
  online: boolean;
  tag: string;
  showChat: boolean;
  inventory: any[]
  showSeasonDialog: boolean;
  leaderboard: any;
  leaderboardIdx: number;
  connected: boolean;
  showContest: boolean;
  contestData: any;
  contestLength: number;
  leagues: { host: string, leagueId: string }[];
  selectedLeague: string;
  leagueExists: boolean;
  showLoginPrompt: boolean;
}

class LobbyPage extends React.Component<LobbyPageProps, LobbyPageState> {
  protected monitorTimer: any;
  protected lastActionTime: number;
  private videoRef = React.createRef<HTMLIFrameElement>();

  constructor(props: LobbyPageProps) {
    super(props);
    this.state = {
      user: 'CryptoJack21',
      channel: '',
      mode: 'chat',
      alert: '',
      loadout: null,
      collection: '',
      type: '',
      online: false,
      tag: '',
      showChat: true,
      inventory: [],
      showSeasonDialog: false,
      leaderboard: null,
      leaderboardIdx: 0,
      connected: false,
      showContest: false,
      contestData: null,
      contestLength: 5,
      leagues: [],
      selectedLeague: '',
      leagueExists: true,
      showLoginPrompt: false,
    }
    this.lastActionTime = 0;
  }

  async componentDidMount(): Promise<void> {
    console.log('Component did mount');
    const channel = this.props.league ? `${this.props.lobbyName}#${this.props.league}` : this.props.lobbyName;
    console.log('Setting channel:', channel);
    
    this.setState({ channel }, async () => {
      if (!Account.isLoggedIn()) {
        window.dispatchEvent(new CustomEvent('showLogin', {
          detail: { callback: this.handleLoginComplete }
        }));
        return;
      }

      await this.connectToNetwork();
      if (Network.isConnected()) {
        this.fetchLeagues();
        console.log('fetchLeagues called after network connection');
      } else {
        console.error('Failed to establish network connection');
      }
    });
  
    let loadout = this.loadLoadout();
    this.setState({ loadout });
  
    this.startMonitoringNetwork();
    this.loadInventory();
    this.getHostLeaderboard();
  }

  handleLoginComplete = () => {
    this.connectToNetwork().then(() => {
      if (Network.isConnected()) {
        this.fetchLeagues();
        this.loadInventory();
        this.getHostLeaderboard();
      }
    });
  }

  async disconnectFromNetwork() {
    if (this.state.connected) {
      console.log('Disconnecting from network');
      await Network.disconnect();
      this.setState({ connected: false });
    }
  }

  async connectToNetwork() {
    if (!Account.isLoggedIn()) {
      console.log('Account is not logged in');
      this.setState({ showLoginPrompt: true });
      return;
    }
  
    const username = Account.getUserName();
    const channel = this.state.channel.toLowerCase();
  
    console.log('Connecting to network - Channel:', channel, 'Username:', username);
  
    if (channel && username) {
      console.log('Attempting to connect to network');
      try {
        await Network.connect();
        // Wait for a short time to ensure the connection is fully established
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        if (Network.isConnected()) {
          Network.registerUser(channel, username);
          this.setState({ connected: true });
          console.log('Successfully connected and registered user');
        } else {
          console.error('Failed to establish connection');
        }
      } catch (error) {
        console.error('Error during network connection:', error);
      }
    } else {
      console.error('Unable to connect: missing channel or username');
      if (!channel) console.error('Channel is missing or undefined');
      if (!username) console.error('Username is missing or undefined');
    }
  }

  async fetchLeagues(retryCount = 0) {
    console.log('fetchLeagues started, retry count:', retryCount);
    try {
      const response = await Races.getLeagues(this.props.lobbyName);
      console.log('get-leagues response:', response);

      if (response.success) {
        const leagues = response.body.leagues.filter(
          (league: { leagueId: string }) => league.leagueId !== this.props.league
        );
        console.log('Filtered leagues:', leagues);
        
        // Check if current league exists in the response
        const leagueExists = response.body.leagues.some(
          (league: { leagueId: string }) => league.leagueId === this.props.league
        );
        
        this.setState({ 
          leagues,
          leagueExists: leagueExists || !this.props.league // true if no league specified
        });
      } else {
        console.error('Failed to fetch leagues:', response);
      }
    } catch (error) {
      console.error('Error fetching leagues:', error);
      if (retryCount < 3) {
        console.log(`Retrying fetchLeagues (attempt ${retryCount + 1})...`);
        await new Promise(resolve => setTimeout(resolve, 2000)); // Wait 2 seconds before retrying
        await this.fetchLeagues(retryCount + 1);
      } else {
        console.error('Max retry attempts reached for fetchLeagues');
      }
    }
  }

  loadLoadout() {
    let loadout = {
      version: LOADOUT_VERSION,
      car: {
        id: Database.getRandomCar().id,
        booster: 'none',
        hood: 'none',
        door: 'none',
        emote: 'none',
        hat: 'none',
        horn: 'none',
        paint: 'none',
        ram:'none',
        trail: 'none',
        underglow: 'none'
      },
      driver: {
        id: Database.getRandomDriver().id,
        celebration: 'none',
        skin: 'none'
      },
      passenger: {
        id: Database.getRandomDriver().id,
        celebration: 'none',
        skin: 'none'
      }
    };

    let s = window.localStorage.getItem('Loadout');

    if(s) {
      let parsedLoadout = JSON.parse(s);
      if(parsedLoadout.version == LOADOUT_VERSION)
        loadout = parsedLoadout;
    }

    return loadout;
  }

  async loadInventory() {
    if(!Account.isLoggedIn())
      return;

    let inventory = await Account.getInventory(true);
    this.setState({inventory});
  }


  getHostLeaderboard = async () => {
    try {
      const host = this.props.league 
        ? `${this.props.lobbyName}#${this.props.league}`
        : this.props.lobbyName;
  
      const response = await Races.getHostLeaderboard(host);
      console.log('Leaderboard response:', response);
      if (response.success) {
        // Preserve the current view state
        const currentShowContest = this.state.showContest;
        
        // Reset contest data but maintain showContest state
        this.setState({ contestData: null }, () => {
          this.processLeaderboard(response.body.leaderboard);
          // After processing, restore the showContest state and prepare contest data if needed
          this.setState({ showContest: currentShowContest }, () => {
            if (currentShowContest) {
              this.prepareContestData();
            }
          });
        });
      } else {
        console.error("Failed to fetch leaderboard");
      }
    } catch (error) {
      console.error("Error fetching leaderboard:", error);
    }
  }

  handleLeagueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLeague = event.target.value;
    if (selectedLeague) {
      window.location.href = `/lobby/${this.props.lobbyName}/${selectedLeague}`;
    }
  }

  processLeaderboard(leaderboard: any) {
    let actions = [];
    let times = [];
    for (let i = 0; i < leaderboard.users.length; i++) {
      actions.push(leaderboard.used.boosts[i] + leaderboard.used.brakes[i] + leaderboard.used.rams[i] + leaderboard.used.shields[i])
      times.push(+(leaderboard.times[i] / 1000).toFixed(2));
    }

    let processedLeaderboard = {
      host: leaderboard.host,
      races: leaderboard.races,
      points: this.createLeaderboard(leaderboard.users, leaderboard.points, true),
      times: this.createLeaderboard(leaderboard.users, times, false),
      damageDealt: this.createLeaderboard(leaderboard.users, leaderboard.damage.dealt, true),
      damageTaken: this.createLeaderboard(leaderboard.users, leaderboard.damage.taken, true),
      actions: this.createLeaderboard(leaderboard.users, actions, true),
      dnf: this.createLeaderboard(leaderboard.users, leaderboard.dnf, true),
      pointsPerRace: leaderboard.points_per_race || {},
    };

    console.log('Processed leaderboard:', processedLeaderboard);
    this.setState({ leaderboard: processedLeaderboard, leaderboardIdx: 0 }, () => {
      this.prepareContestData();
    });
  }

  toggleContest = (length: number) => {
    console.log('toggleContest called with length:', length);
    const newShowContest = !(this.state.showContest && this.state.contestLength === length);
    
    this.setState(prevState => ({
      showContest: newShowContest,
      contestLength: newShowContest ? length : prevState.contestLength,
      leaderboardIdx: newShowContest ? prevState.leaderboardIdx : 0 // Reset to 'Points' tab if turning off contest
    }), () => {
      if (this.state.showContest) {
        this.prepareContestData(this.state.contestLength); // Use the updated contestLength
      }
    });
  }


  prepareContestData = (length: number = 5) => {
    console.log('prepareContestData called with length:', length);
    const { pointsPerRace } = this.state.leaderboard;
    if (!pointsPerRace || Object.keys(pointsPerRace).length === 0) {
      console.log('No pointsPerRace data available');
      this.setState({ contestData: [] });
      return;
    }
  
    const contestData = Object.entries(pointsPerRace).map(([name, races]: [string, unknown]) => {
      if (!Array.isArray(races)) {
        console.warn(`Invalid race data for ${name}`);
        return { name, races: [], total: 0 };
      }
  
      const contestRaces = races.slice(0, length).map(points => 
        typeof points === 'number' ? points : 0
      );
      const total = contestRaces.reduce((sum, points) => sum + points, 0);
      return { name, races: contestRaces, total };
    });
  
    contestData.sort((a, b) => b.total - a.total);
  
    console.log('Contest data prepared:', contestData);
    this.setState({ contestData });
  }
  
  renderContest() {
    console.log('renderContest called, contestData:', this.state.contestData);
    if (!this.state.contestData) return null;
  
    const containerStyle: React.CSSProperties = {
      backgroundColor: '#0a192f',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      padding: '20px',
      maxWidth: '100%',
      margin: '20px 0',
      color: '#e6f1ff',
    };
  
    const tableStyle: React.CSSProperties = {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: '0 8px',
    };
  
    const cellStyle: React.CSSProperties = {
      padding: '12px',
      textAlign: 'left',
    };
  
    return (
      <div style={containerStyle}>
        <h2 style={{ color: '#ffd700', marginBottom: '20px' }}>First {this.state.contestLength} Races</h2>
        <div style={{ overflowX: 'auto' }}>
          <table style={tableStyle}>
            <thead>
              <tr style={{ backgroundColor: '#172a45' }}>
                <th style={cellStyle}>Rank</th>
                <th style={cellStyle}>Name</th>
                {[...Array(this.state.contestLength)].map((_, idx) => (
                  <th key={idx} style={cellStyle}>Race {idx + 1}</th>
                ))}
                <th style={cellStyle}>Total</th>
              </tr>
            </thead>
            <tbody>
              {this.state.contestData.map((entry: any, idx: number) => (
                <tr key={idx} style={{ backgroundColor: '#172a45' }}>
                  <td style={cellStyle}>{idx + 1}</td>
                  <td style={cellStyle}>{entry.name}</td>
                  {[...Array(this.state.contestLength)].map((_, raceIdx) => (
                    <td key={raceIdx} style={cellStyle}>
                      {entry.races[raceIdx] !== undefined ? entry.races[raceIdx] : '-'}
                    </td>
                  ))}
                  <td style={cellStyle}>{entry.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  createLeaderboard(users: string[], values: number[], descending: boolean): any[] {
    let entries = [];
    for (let i = 0; i < users.length; i++)
      if (values[i] > 0)
        entries.push({ name: users[i], value: values[i] });

    entries.sort((a, b) => {
      if (a.value > b.value)
        return 1 * (descending ? -1 : 1);
      else if (a.value < b.value)
        return -1 * (descending ? -1 : 1);

      if (a.name > b.name)
        return 1;
      else if (a.name < b.name)
        return -1;

      return 0;
    });

    return entries;
  }

  onStat = (idx: number) => {
    this.setState({ 
      leaderboardIdx: idx,
      showContest: false  // Add this line to turn off contest view
    });
  }

  startMonitoringNetwork() {
    this.monitorTimer = setInterval(() => {
      let online = Network.isConnected();
      if(this.state.online != online)
        this.setState({online});
    }, 1000);
  }

  stopMonitoringNetwork() {
    clearInterval(this.monitorTimer);
  }

  sendActionEvent(action:string, data:any = null) {
    let event:any = {
      id: 'game-action', 
      channel: this.props.lobbyName.toLowerCase(), // Use only the lobbyName prop
      user: {
        id: Account.getUserId(),
        name: Account.getUserName()
      },
      action
    };

    if(data)
      event.data = data;

    Network.sendEvent(event);
  }

  saveLoadout() {
    let s = JSON.stringify(this.state.loadout);
    window.localStorage.setItem('Loadout', s);
    this.sendActionEvent('loadout', this.state.loadout);
  }

  onAction(action:string) {
    if(!Account.isLoggedIn()) {
      this.setState({alert: 'Please login to play.'});
      return;
    }

    if(!Network.isConnected()) {
      this.setState({alert: 'Not connected to the network!'});
      return;
    }

    this.sendActionEvent(action);
  }

  onJoinRace() {
    if(!Account.isLoggedIn()) {
      this.setState({alert: 'Please login to play.'});
      return;
    }

    if(!Network.isConnected()) {
      this.setState({alert: 'Not connected to the network!'});
      return;
    }

    this.sendActionEvent('race', this.state.loadout);
  }

  onCustomizeSave() {
    this.saveLoadout();
    this.setState({mode: 'chat'})
  }

  onCustomizeClose() {
    let loadout = this.loadLoadout();
    this.setState({loadout, mode: 'chat'});    
  }

  onCarClick(id:string) {
    let loadout = {...this.state.loadout};
    loadout.car.id = id;
    this.setState({loadout, mode: 'customize-car'});
  }

  onCosmetic(collection:string, tag:string, type:string) {
    this.setState({mode: 'cosmetics', type, tag, collection})
  }

  onCosmeticClick(asset:string, collection:string, tags:string[]) {
    let loadout = {...this.state.loadout};

    if(collection == 'boosters') loadout.car.booster = asset;
    if(collection == 'decals' && tags.indexOf('hood') != -1) loadout.car.hood = asset;
    if(collection == 'decals' && tags.indexOf('door') != -1) loadout.car.door = asset;
    if(collection == 'emotes') loadout.car.emote = asset;
    if(collection == 'hats') loadout.car.hat = asset;
    if(collection == 'horns') loadout.car.horn = asset;
    if(collection == 'paint') loadout.car.paint = asset;
    if(collection == 'rams') loadout.car.ram = asset;
    if(collection == 'trails') loadout.car.trail = asset;
    if(collection == 'underglows') loadout.car.underglow = asset;
    if(collection == 'skins') loadout[this.state.type].skin = asset;
    if(collection == 'celebrations') loadout[this.state.type].celebration = asset;

    this.setState({loadout, mode: this.state.type == 'car' ? 'customize-car' : 'customize-drivers'});
  }


  onOpenSeasonDialog = () => {
    this.setState({ showSeasonDialog: true });
    this.getSeasonData();
  }

  onCloseSeasonDialog = () => {
    this.setState({ showSeasonDialog: false });
  }

  getSeasonData = async () => {
    try {
      const result = await PlayFab.executeCloudScript("getSeason", {});
      if (result.success) {
        console.log("Season data retrieved successfully:", result.data);
        // You can update the state here if needed
      } else {
        console.error("Failed to retrieve season data:", result);
      }
    } catch (error) {
      console.error('Error retrieving season data:', error);
    }
  }

  setMode(mode:string) {
    if(!Account.isLoggedIn()) {
      this.setState({alert: 'Please login to play.'});
      return;
    }

    if(!Network.isConnected()) {
      this.setState({alert: 'Not connected to the network!'});
      return;
    }

    this.setState({mode});
  }

  onPreviousCar() {
    let loadout = {...this.state.loadout};

    let ci = Database.getCarIndex(loadout.car.id);

    if(ci == -1) 
      loadout.car.id = Database.getLastCar().id;
    else if(ci == 0)
      loadout.car.id = 'random';
    else
      loadout.car.id = Database.getPreviousCar(loadout.car.id).id;

    this.setState({loadout});
  }

  onNextCar() {
    let loadout = {...this.state.loadout};

    let ci = Database.getCarIndex(loadout.car.id);

    if(ci == -1) 
      loadout.car.id = Database.getFirstCar().id;
    else if(ci == Database.getTotalCars() - 1)
      loadout.car.id = 'random';
    else
      loadout.car.id = Database.getNextCar(loadout.car.id).id;

    this.setState({loadout});
  }

  onPreviousDriver(type: 'driver' | 'passenger') {
    let loadout = {...this.state.loadout};
    let di = Database.getDriverIndex(loadout[type].id);
    if(di == -1) 
      loadout[type].id = Database.getLastDriver().id;
    else if(di == 0)
      loadout[type].id = 'random';
    else
      loadout[type].id = Database.getPreviousDriver(loadout[type].id).id;
    this.setState({loadout});
  }

  onNextDriver(type: 'driver' | 'passenger') {
    let loadout = {...this.state.loadout};
    let di = Database.getDriverIndex(loadout[type].id);
    if(di == -1) 
      loadout[type].id = Database.getFirstDriver().id;
    else if(di == Database.getTotalDrivers()-1)
      loadout[type].id = 'random';
    else
      loadout[type].id = Database.getNextDriver(loadout[type].id).id;
    this.setState({loadout});
  }

  onSelectDriver(type: 'driver' | 'passenger') {
    this.setState({type, mode: 'drivers'});
  }

  onDriverClick(id: string) {
    let loadout = {...this.state.loadout};
    loadout[this.state.type].id = id;
    this.setState({loadout, mode: 'customize-drivers'})
  }

  onSwap() {
    let loadout = {...this.state.loadout};
    let driver = loadout['driver'].id;
    loadout['driver'].id = loadout['passenger'].id;
    loadout['passenger'].id = driver;
    this.setState({loadout})
  }

  onToggleChat() {
    this.setState({showChat: !this.state.showChat});
  }

  renderVideo() {
    return null;
  }

  renderChat() {
    return null;
  }

  renderCarsDialog() {
    let divs = [];
    for(let i = 0; i < Database.cars.length; i++) {
      let car = Database.cars[i];
      let src = `/images/cars/${car.id}.png`;
      divs.push(
        <div key={i} className="flex-column" style={{rowGap: '5px', cursor: 'pointer'}} onClick={()=>this.onCarClick(car.id)}>
          <img style={{width: '100%'}} src={src} />
          <div style={{fontSize: '0.8em', textAlign: 'center'}}>{car.name}</div>
        </div>
      )
    }

    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered " style={{padding: '0px', maxHeight: '525px'}}>
          <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}>
            <div style={{width: '30px', paddingLeft: '10px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: 'customize-car'})}>
              <BsArrowLeftCircleFill />
            </div>
            <div style={{textAlign: 'center'}}>Select Car</div>
            <div style={{width: '30px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: 'chat'})}>
              <BsFillXCircleFill />
            </div>
          </div>
          <div className="host-page-modal-content">
            {divs}
          </div>
        </div>
      </div>
    )
  }

  renderDriversDialog() {
    let divs = [];
    for(let i = 0; i < Database.drivers.length; i++) {
      let driver = Database.drivers[i];
      let src = `/images/drivers/${driver.id}.png`;
      divs.push(
        <div key={i} className="flex-column" style={{rowGap: '5px', cursor: 'pointer'}} onClick={()=>this.onDriverClick(driver.id)}>
          <img style={{width: '100%', borderRadius: '3px'}} src={src} />
          <div style={{fontSize: '0.8em', textAlign: 'center'}}>{driver.nick ? driver.nick : driver.name}</div>
        </div>
      )
    }

    let title = 'Select Driver';
    if(this.state.mode == 'passengers')
      title = 'Select Passenger'

    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered" style={{padding: '0px', maxHeight: '600px'}}>
          <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}>
            <div style={{width: '30px', paddingLeft: '10px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: 'customize-drivers'})}>
              <BsArrowLeftCircleFill />
            </div>
            <div style={{textAlign: 'center'}}>{title}</div>
            <div style={{width: '30px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: 'chat'})}>
              <BsFillXCircleFill />
            </div>
          </div>
          <div className="host-page-modal-content">
            {divs}
          </div>
        </div>
      </div>
    )
  }

  refreshInventory = async () => {
    try {
      await Account.getInventory(true);
      this.forceUpdate();
    } catch (error) {
      console.error('Error refreshing inventory:', error);
    }
  }

  renderCosmeticsDialog() {
    let collection = Database.getCollection(this.state.collection)
    let assets = Database.getAssets(this.state.collection);

    let divs = [];

    divs.push(
      <div key={assets.length} className="flex-column" style={{rowGap: '5px', cursor: 'pointer'}} onClick={()=>this.onCosmeticClick('none', this.state.collection, [this.state.tag])}>
        <img style={{width: '100%', borderRadius: '5px'}} src="/images/none.png" />
        <div style={{fontSize: '0.8em', textAlign: 'center'}}>None</div>
      </div>
    )

    for(let i = 0; i < assets.length; i++) {
      let asset = assets[i];
      if(this.state.tag && asset.tags.indexOf(this.state.tag) == -1)
        continue;
      
      // Check if the asset is in the player's inventory
      let existing = this.state.inventory.find((a:any) => a.id == asset.id);
      
      // If it's not in the inventory and not in development mode, skip it
      if(!existing && (this.state.channel != 'evolverstudios') && (this.state.channel != 'turisstation'))
        continue;
      
      let src = `/images/assets/${this.state.collection}/${asset.image ? asset.image : asset.id}.png`;
      divs.push(
        <div key={i} className="flex-column" style={{rowGap: '5px', cursor: 'pointer'}} onClick={()=>this.onCosmeticClick(asset.id, asset.collection, asset.tags)}>
          <img style={{width: '100%', borderRadius: '5px'}} src={src} />
          <div style={{fontSize: '0.8em', textAlign: 'center'}}>{asset.name}</div>
        </div>
      )
    }

    let title = collection.name;
    
    if(collection.name.substring(collection.name.length-1) == 's')
      title = collection.name.substring(0, collection.name.length-1);

    let backMode = this.state.type == 'car' ? 'customize-car' : 'customize-drivers';

    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered" style={{padding: '0px', height: '525px'}}>
          <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{width: '30px', paddingLeft: '10px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: backMode})}>
                <BsArrowLeftCircleFill />
              </div>
              <div style={{width: '30px', display: 'flex', color: 'gray', cursor: 'pointer', marginLeft: '10px'}} onClick={() => this.loadInventory()}>
                <BsArrowClockwise />
              </div>
            </div>
            <div style={{textAlign: 'center'}}>Select {title}</div>
            <div style={{width: '30px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.setState({mode: 'chat'})}>
              <BsFillXCircleFill />
            </div>
          </div>
          <div className="host-page-modal-content">
            {divs}
          </div>
        </div>
      </div>
    )
  }

  renderStatBar(key:number, label:string, value:number) {
    let pips = [];
    for(let i = 0; i < 5; i++) {
      let color = i < value ? 'orange' : '#ffffff10';
      pips.push(
        <div key={i} style={{height: '10px', width: '100%', borderRadius: '5px', backgroundColor: color}} />
      )
    }

    let barWidth = `${value * 20}%`;
    let background = 'linear-gradient(197.78deg, #F1BD42 -12.54%, #EF8F49 104.72%)';
    return (
      <div key={key}>
        <div style={{color: 'gray'}}>{label}</div>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', columnGap: '5px', marginTop: '3px'}}>
          {pips}
        </div>

        {/* <div style={{backgroundColor: 'black', width: '100%', height: '10px', borderRadius: '5px', marginTop: '2px', position: 'relative'}}>
          <div style={{background, width: barWidth, height: '100%', borderRadius: '5px', position: 'absolute', top: '0', left: '0'}} />
        </div> */}
      </div>
    )    
  }

  renderDriverStatBar(key:number, label:string, driver:number, passenger:number) {
    let pips = [];
    let total = 0;
    for(let i = 0; i < 8; i++) {
      let color = i < driver + passenger ? total < driver ? 'orange' : 'green' : '#ffffff10';
      total++;
      pips.push(
        <div key={i} style={{height: '15px', width: '100%', borderRadius: '5px', backgroundColor: color}} />
      )
    }

    return (
      <div key={key} style={{display: 'grid', gridTemplateColumns: '80px 1fr', alignItems: 'center'}}>
        <div style={{fontSize: '0.9em', color: 'gray', textAlign: 'left'}}>{label}</div>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr', columnGap: '3px'}}>
          {pips}
        </div>
      </div>
    )    
  }

  renderAnalysisStatBar(key:number, label:string, values:number[]) {
    let pips = [];
    let total = 0;
    for(let i = 0; i < 8; i++) {
      let color = '';
      if(values[i] == 0)
        color = '#ffffff10';
      else if(values[i] <= 62)
        color = 'green';
      else if(values[i] <= 93)
        color = 'orange';
      else
        color = 'red';
      // else if(values > 20 && values < 50)
      //   color = 'yellow';
      // else if(values > 50 && values < 50)
      //   color = 'yellow';
      // else if(values > 20 && values < 50)
      //   color = 'yellow';
      total++;
      pips.push(
        <div key={i} style={{fontSize: '0.7em', color: 'black', height: '15px', width: '100%', borderRadius: '5px', backgroundColor: color}}>
          {/* {((values[i]/441)*100).toFixed(1)} */}
          {/* {((values[i]/441)*100).toFixed(0)}% */}
          {values[i]}
        </div>
      )
    }

    return (
      <div key={key} style={{display: 'grid', gridTemplateColumns: '80px 1fr', alignItems: 'center'}}>
        <div style={{fontSize: '0.9em', color: 'gray', textAlign: 'left'}}>{label}</div>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr', columnGap: '3px'}}>
          {pips}
        </div>
      </div>
    )    
  }

  renderCarPanel() {
    let car = null;
    let carIdx = Database.getTotalCars();
    let carImage = `/images/random-car.png`;
    let carName = 'Random';

    if(this.state.loadout.car.id != 'random') {
      car = Database.getCar(this.state.loadout.car.id);
      carIdx = Database.getCarIndex(this.state.loadout.car.id);
      carImage = `/images/cars/${car.id}.png`;
      carName = car.name;
    }
    
    return (
      <div className="flex-row" style={{backgroundColor: 'var(--background-color)', padding: '10px', borderRadius: '5px'}}>
        <div style={{display: 'grid', gridTemplateColumns: '150px 1fr', columnGap: '15px', width: '100%'}}>
          <div>
            <img style={{width: '100%', borderRadius: '5px', cursor: 'pointer'}} src={carImage} onClick={()=>this.setMode('cars')} />
            <div className="flex-row" style={{width: '100%', justifyContent: 'space-between', color: 'gray', alignItems: 'center'}}>
              <div style={{cursor: 'pointer', transform: 'translateY(3px)'}} onClick={()=>this.onPreviousCar()} >
                <BsArrowLeftCircleFill />
              </div>
              <div style={{color: 'white'}}>{carIdx+1}/{Database.getTotalCars()+1}</div>
              <div style={{cursor: 'pointer', transform: 'translateY(3px)'}} onClick={()=>this.onNextCar()} >
                <BsArrowRightCircleFill />
              </div>
            </div>
          </div>
          <div className="flex-column" style={{rowGap: '0px', textAlign: 'left'}}>
            <div>{carName}</div>
            <div className="flex-column" style={{color: 'gray', fontSize: '0.8em', rowGap: '5px', marginTop: '5px'}}>
              {this.renderStatBar(0, 'Weight', car ? car.weight : 0)}
              {this.renderStatBar(1, 'Health', car ? car.health : 0)}
              {this.renderStatBar(2, 'Speed', car ? car.speed : 0)}
              {this.renderStatBar(3, 'Accel', car ? car.acceleration : 0)}
              {this.renderStatBar(4, 'Handling', car ? car.handling : 0)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderCosmeticPanel(key:number, cosmetic:any, type:string) {
    let cosDiv = <div>{cosmetic.name}</div>
    if(cosmetic.asset != 'none') {
      let asset = Database.getAsset(cosmetic.asset);
      let src = `/images/assets/${asset.collection}/${asset.image ? asset.image : asset.id}.png`;
      cosDiv = <img style={{width: '100%', height: '100%', borderRadius: '5px'}} src={src} />;
    }

    return (
      <div key={key} className="host-page-car-cosmetic" onClick={()=>this.onCosmetic(cosmetic.collection, cosmetic.tag, type)}>
        {cosDiv}
      </div>
    )
  }

  renderCarCosmeticsPanel() {
    let loadout = this.state.loadout;

    let cosmetics = [
      {collection: 'paint', name: 'Paint', asset: loadout.car.paint},
      {collection: 'decals', name: 'Hood', tag: 'hood', asset: loadout.car.hood},
      {collection: 'decals', name: 'Door', tag: 'door', asset: loadout.car.door},
      {collection: 'trails', name: 'Trail', asset: loadout.car.trail},
      {collection: 'underglows', name: 'Glow', asset: loadout.car.underglow},
      {collection: 'emotes', name: 'Emote', asset: loadout.car.emote},
      {collection: 'boosters', name: 'Boost', asset: loadout.car.booster},
      {collection: 'rams', name: 'Ram', asset: loadout.car.ram},
      {collection: 'horns', name: 'Horn', asset: loadout.car.horn},
      {collection: 'hats', name: 'Hat', asset: loadout.car.hat}
    ]

    let divs = [];
    for(let i = 0; i < cosmetics.length; i++) 
      divs.push(this.renderCosmeticPanel(i, cosmetics[i], 'car'))
    
    return (
      <div className="flex-row" style={{backgroundColor: 'var(--background-color)', padding: '10px', borderRadius: '5px'}}>
        <div className="flex-row" style={{flexWrap: 'wrap', rowGap: '5px', columnGap: '5px', width: '100%'}}>
          {divs}
        </div>
      </div>
    )
  }

  renderCustomizeCarDialog() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered customize-car-dialog" style={{maxWidth: '400px', padding: '0px', height: '525px'}}>
          <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px'}}>
            <div style={{width: '30px', paddingLeft: '10px', display: 'flex', color: 'gray', cursor: 'pointer'}}>
              &nbsp;
            </div>
            <div style={{textAlign: 'center'}}>Customize Car</div>
            <div style={{width: '30px', display: 'flex', color: 'gray', cursor: 'pointer'}} onClick={()=>this.onCustomizeClose()}>
              <BsFillXCircleFill />
            </div>
          </div>
          <div className="flex-column" style={{rowGap: '10px', margin: '10px'}}>
            {this.renderCarPanel()}
            {this.renderCarCosmeticsPanel()}
            <div><button style={{width: '120px', margin: '5px 0px 5px 0px'}} onClick={()=>this.onCustomizeSave()}>Save</button></div>
          </div>
        </div>
      </div>
    );
  }

  renderDriverPanel(type: 'driver' | 'passenger') {
    let driverId = this.state.loadout[type].id;

    let driver = null;
    let driverIdx = Database.getTotalDrivers();
    let driverImage = `/images/random-driver.png`;
    let driverName = 'Random';

    if(driverId != 'random') {
      driver = Database.getDriver(driverId);
      driverIdx = Database.getDriverIndex(driverId);
      driverImage = `/images/drivers/${driverId}.png`;
      driverName = driver.name;
    }

    let celebration = 'None';
    if(this.state.loadout[type].celebration != 'none') {
      let asset = Database.getAsset(this.state.loadout[type].celebration);
      celebration = asset.name;
    }

  return (
    <div className="driver-panel flex-column" style={{rowGap: '5px'}}>
      <img 
        className="driver-image"
        style={{
          width: '100%', 
          maxWidth: '150px', 
          borderRadius: '5px', 
          cursor: 'pointer'
        }} 
        src={driverImage} 
        onClick={()=>this.onSelectDriver(type)} 
      />
      <div>{driverName}</div>
      <div className="flex-row" style={{margin: 'auto', width: '85%', justifyContent: 'space-between', color: 'gray', alignItems: 'center'}}>
        <div style={{cursor: 'pointer', transform: 'translateY(3px)'}} onClick={()=>this.onPreviousDriver(type)} >
          <BsArrowLeftCircleFill />
        </div>
        <div style={{fontSize: '0.9em'}}>{driverIdx+1}/{Database.getTotalDrivers()+1}</div>
        <div style={{cursor: 'pointer', transform: 'translateY(3px)'}} onClick={()=>this.onNextDriver(type)} >
          <BsArrowRightCircleFill />
        </div>
      </div>
      <div className="host-page-celebration-button" onClick={()=>this.onCosmetic('celebrations', '', type)}>
        <div className="subtext" style={{fontSize: '0.8em'}}>Celebration</div>
        <div>{celebration}</div>
      </div>
    </div>
  )
}

  renderCustomizeDriversDialog() {
    let driverId = this.state.loadout['driver'].id;
    let driver = Database.getDriver(driverId);

    let passengerId = this.state.loadout['passenger'].id;
    let passenger = Database.getDriver(passengerId);

    let statDivs = [];

    statDivs.push(this.renderDriverStatBar(5, 'Actions', driver ? Math.ceil(driver.actions * 0.5) : 0, passenger ? passenger.actions : 0));
    statDivs.push(this.renderDriverStatBar(1, 'Boosting', driver ? driver.boosting : 0, passenger ? Math.ceil(passenger.boosting * 0.5) : 0));
    statDivs.push(this.renderDriverStatBar(0, 'Driving', driver ? driver.driving : 0, passenger ? Math.ceil(passenger.driving * 0.5) : 0));
    statDivs.push(this.renderDriverStatBar(2, 'Morale', driver ? Math.ceil(driver.morale * 0.5) : 0, passenger ? passenger.morale : 0));
    statDivs.push(this.renderDriverStatBar(3, 'Ramming', driver ? driver.ramming : 0, passenger ? Math.ceil(passenger.ramming * 0.5) : 0));
    statDivs.push(this.renderDriverStatBar(4, 'Shielding', driver ? Math.ceil(driver.shielding * 0.5) : 0, passenger ? passenger.shielding : 0));

    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered customize-driver-dialog" style={{maxWidth: '400px', width: '90vw', maxHeight: '90vh', padding: '10px', overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px'
          }}>
            <div style={{width: '30px'}}></div>
            <div>Customize Driver & Passenger</div>
            <div style={{width: '30px', cursor: 'pointer'}} onClick={() => this.onCustomizeClose()}>
              <BsFillXCircleFill />
            </div>
          </div>
          <div style={{
            flexGrow: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-around',
              gap: '10px',
              backgroundColor: 'var(--background-color)',
              padding: '10px',
              borderRadius: '5px'
            }}>
              <div style={{flex: '1'}}>
                {this.renderDriverPanel('driver')}
              </div>
              <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={() => this.onSwap()}>
                <BsArrowsExpandVertical style={{fontSize: '1.5em'}} />
              </div>
              <div style={{flex: '1'}}>
                {this.renderDriverPanel('passenger')}
              </div>
            </div>
            <div className="driver-stats" style={{
              backgroundColor: 'var(--background-color)',
              padding: '10px',
              borderRadius: '5px',
            }}>
              {statDivs}
            </div>
          </div>
          <div style={{marginTop: '10px', textAlign: 'center'}}>
            <button style={{width: '120px'}} onClick={() => this.onCustomizeSave()}>Save</button>
          </div>
        </div>
      </div>
    );
  }

  renderSeasonDialog() {
    if (!this.state.showSeasonDialog) return null;

    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered" style={{width: '90%', maxWidth: '800px', height: '90%', padding: '20px'}}>
          <div className="flex-row" style={{justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
            <h2>Season Information</h2>
            <div style={{cursor: 'pointer'}} onClick={this.onCloseSeasonDialog}>
              <BsFillXCircleFill />
            </div>
          </div>
          <div style={{height: 'calc(100% - 60px)', overflowY: 'auto'}}>
            <SeasonPage />
          </div>
        </div>
      </div>
    );
  }

  renderButtons() {
    return (
      <div className="host-page-buttons">
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          gridTemplateRows: 'repeat(3, auto)',
          gap: '5px',
          width: '100%'
        }}>
          <button style={{padding: '5px 0'}} onClick={()=>this.onAction('boost')}>
            <span className="corner-emoji">🗲</span>
            🚀<br/>Boost
          </button>
          <button style={{padding: '5px 0'}} onClick={()=>this.onAction('ram')}>
            <span className="corner-emoji">🗲</span>
            🔰<br/>Ram
          </button>
          <button style={{padding: '5px 0'}} onClick={()=>this.onJoinRace()}>🏁<br/>Join</button>
          <button style={{padding: '5px 0'}} onClick={()=>this.setMode('customize-car')}>🚗<br/>Car</button>
          <button style={{padding: '5px 0'}} onClick={()=>this.setMode('customize-drivers')}>🧑<br/>Drivers</button>
          
          <button style={{padding: '5px 0'}} onClick={()=>this.onAction('brake')}>
            <span className="corner-emoji">🗲</span>
            🛑<br/>Brake
          </button>
          <button style={{padding: '5px 0'}} onClick={()=>this.onAction('shield')}>
            <span className="corner-emoji">🗲</span>
            🛡️<br/>Shield
          </button>
          <button style={{padding: '5px 0'}} onClick={()=>this.onAction('emote')}>😐<br/>Emote</button>
          <button style={{padding: '5px 0'}} onClick={()=>this.onAction('horn')}>🎺<br/>Horn</button>
          <button style={{padding: '5px 0'}} onClick={this.onOpenSeasonDialog}>🏆<br/>Season</button>

          <button style={{padding: '5px 0'}} onClick={()=>this.onAction('special-driver')}>
            <span className="corner-emoji">🗲</span>
            🌟<br/>Driver Special
          </button>
          <button style={{padding: '5px 0'}} onClick={()=>this.onAction('special-passenger')}>
            <span className="corner-emoji">🗲</span>
            ✨<br/>Passenger Special
          </button>
          <div style={{gridColumn: 'span 3'}}></div>
          {/* <button style={{padding: '5px 0'}} onClick={this.onActivateRemoteClip}>🎬<br/>Clip</button> */}
        </div>
        <div className="host-page-network" style={{backgroundColor: this.state.online ? 'green' : 'red', position: 'absolute', bottom: '5px', left: '5px'}} />
        
        <div className="host-page-chat-button" onClick={()=>this.onToggleChat()} style={{position: 'absolute', bottom: '5px', right: '5px'}}>

          {this.state.showChat ? 
            <BsFillCaretRightFill style={{transform: 'translateY(-3px)'}} /> : 
            <BsFillCaretLeftFill style={{transform: 'translateY(-3px)'}} /> 
          }
          <BsWechat style={{fontSize: '1.5em'}} />
        </div>
      </div>
    )
  }




  renderMobile() {
    return (
      <div className="page">
        <div className="flex-column">
          {this.renderVideo()}
          {this.renderButtons()}
          {this.renderChat()}
        </div>
      </div>
    )
  }

  renderAlertDialog() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel" style={{width: '250px'}}>
          <div>{this.state.alert}</div>
          <div>
            <button style={{marginTop: '25px'}} onClick={()=>this.setState({alert: ''})}>OK</button>
          </div>
        </div>
      </div>
    )
  }

  renderLeagueDropdown() {
    const { leagues } = this.state;
    const { league, lobbyName } = this.props;

    if (!leagues) {
      return null;
    }

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = event.target.value;
      if (selectedValue === 'overall') {
        window.location.href = `/lobby/${lobbyName}`;
      } else {
        window.location.href = `/lobby/${lobbyName}/${selectedValue}`;
      }
    };

    return (
      <select 
        value={league || ''}
        onChange={handleChange}
        className="host-page-button league-dropdown"
      >
        <option value="">Select a League</option>
        <option value="overall">Overall Leaderboard</option>
        {leagues.map((leagueOption) => (
          <option key={leagueOption.leagueId} value={leagueOption.leagueId}>
            {leagueOption.leagueId}
          </option>
        ))}
      </select>
    );
  }

  renderLeaderboard() {
    if (!this.state.leaderboard) {
      if (this.props.league && !this.state.leagueExists) {
        return (
          <div style={{
            backgroundColor: '#0a192f',
            borderRadius: '8px',
            padding: '20px',
            margin: '20px 0',
            color: '#e6f1ff',
            textAlign: 'center'
          }}>
            <h3 style={{color: '#ffd700'}}>League Not Started</h3>
            <p>The league "{this.props.league}" has not started yet.</p>
            <p>After the first race, the leaderboard will be updated upon refresh.</p>
            <button onClick={this.getHostLeaderboard} style={{
              display: 'inline-flex',
              alignItems: 'center',
              padding: '8px 16px',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              color: '#ffffff',
              fontSize: '14px',
              fontWeight: 500,
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              marginTop: '10px'
            }}>
              <BsArrowClockwise style={{ marginRight: '8px' }} /> Refresh
            </button>
          </div>
        );
      }
      return null;
    }

    console.log('renderLeaderboard called, showContest:', this.state.showContest);

  
    const leaderboardTypes = ['points', 'times', 'damageDealt', 'damageTaken', 'actions', 'dnf'];
    const leaderboardTitles = ['Points', 'Fastest Race', 'Damage Dealt', 'Damage Taken', 'Actions', 'Did Not Finish'];
    const leaderboardIcons = [BsTrophy, BsLightning, BsShield, BsBullseye, BsExclamationTriangle, BsExclamationTriangle];
    const currentLeaderboard = this.state.leaderboard[leaderboardTypes[this.state.leaderboardIdx]];
  
    const containerStyle: React.CSSProperties = {
      backgroundColor: '#0a192f',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      padding: '20px',
      maxWidth: '100%',
      margin: '20px 0',
      color: '#e6f1ff',
    };
  
    const headerStyle: React.CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '20px',
    };
  
    const titleStyle: React.CSSProperties = {
      fontSize: '24px',
      fontWeight: 600,
      color: '#ffd700',
      margin: '0 0 10px 0',
    };
  
    const tabsStyle: React.CSSProperties = {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      marginBottom: '20px',
    };
  
    const tabStyle: React.CSSProperties = {
      padding: '8px 12px',
      fontSize: '14px',
      fontWeight: 500,
      color: '#ffffff',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      display: 'flex',
      alignItems: 'center',
    };
  
    const activeTabStyle: React.CSSProperties = {
      ...tabStyle,
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    };
  
    const tableStyle: React.CSSProperties = {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: '0 8px',
    };
  
    const cellStyle: React.CSSProperties = {
      padding: '12px',
      textAlign: 'left',
    };
  
    const rankCellStyle: React.CSSProperties = {
      ...cellStyle,
      width: '10%',
      fontWeight: 'bold',
    };
  
    const nameCellStyle: React.CSSProperties = {
      ...cellStyle,
      width: '60%',
    };
  
    const valueCellStyle: React.CSSProperties = {
      ...cellStyle,
      width: '30%',
      textAlign: 'right',
    };
  
    const refreshButtonStyle: React.CSSProperties = {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '8px 16px',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      color: '#ffffff',
      fontSize: '14px',
      fontWeight: 500,
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
    };
  
    return (
      <div style={containerStyle}>
        <div style={headerStyle}>
          <h2 style={titleStyle}>{this.state.leaderboard.host}'s Leaderboard</h2>
          <span style={{color: '#8892b0', fontSize: '14px'}}>Total Races: {this.state.leaderboard.races}</span>
        </div>
        <div style={tabsStyle}>
          {this.renderLeagueDropdown()} {/* Add the league dropdown here */}
          {leaderboardTitles.map((title, idx) => {
            const Icon = leaderboardIcons[idx];
            return (
              <button
                key={idx}
                onClick={() => this.onStat(idx)}
                style={this.state.leaderboardIdx === idx && !this.state.showContest ? activeTabStyle : tabStyle}
              >
                <Icon style={{ marginRight: '8px' }} /> {title}
              </button>
            );
          })}
          <button
            onClick={() => this.toggleContest(5)}
            style={this.state.showContest && this.state.contestLength === 5 ? activeTabStyle : tabStyle}
          >
            <BsFlagFill style={{ marginRight: '8px' }} /> 5 Races
          </button>
          <button
            onClick={() => this.toggleContest(10)}
            style={this.state.showContest && this.state.contestLength === 10 ? activeTabStyle : tabStyle}
          >
            <BsFlagFill style={{ marginRight: '8px' }} /> 10 Races
          </button>
          <button
            onClick={() => this.toggleContest(15)}
            style={this.state.showContest && this.state.contestLength === 15 ? activeTabStyle : tabStyle}
          >
            <BsFlagFill style={{ marginRight: '8px' }} /> 15 Races
          </button>
        </div>
        {this.state.showContest ? (
          this.renderContest()
        ) : (
          <div style={{ overflowX: 'auto' }}>
            <table style={tableStyle}>
              <tbody>
                {currentLeaderboard.slice(0, 10).map((entry: any, idx: number) => (
                  <tr key={idx} style={{ backgroundColor: '#172a45' }}>
                    <td style={rankCellStyle}>
                      <span style={{
                        display: 'inline-block',
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        backgroundColor: idx < 3 ? ['#ffd700', '#c0c0c0', '#cd7f32'][idx] : '#1f4068',
                        color: idx < 3 ? '#0a192f' : '#e6f1ff',
                        textAlign: 'center',
                        lineHeight: '24px',
                        fontSize: '12px',
                      }}>
                        {idx + 1}
                      </span>
                    </td>
                    <td style={nameCellStyle}>{entry.name}</td>
                    <td style={valueCellStyle}>{entry.value.toFixed(this.state.leaderboardIdx === 1 ? 2 : 0)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div style={{marginTop: '20px', textAlign: 'right'}}>
          <button onClick={this.getHostLeaderboard} style={refreshButtonStyle}>
            <BsArrowClockwise style={{ marginRight: '8px' }} /> Refresh
          </button>
        </div>
      </div>
    );
  }

  render() {
    if(this.state.channel == '')
      return null;

    return (
      <div className="page">
        <div className="lobby-page-container">
          {this.state.showLoginPrompt ? (
            <div className="dialog-cover">
              <div className="dialog-panel" style={{width: '250px'}}>
                <div style={{textAlign: 'center', marginBottom: '15px'}}>
                  <h3>Welcome to {this.props.lobbyName}'s Lobby</h3>
                  <p>Please login to participate</p>
                </div>
                <div style={{textAlign: 'center'}}>
                  <button 
                    className="primary-button"
                    onClick={() => {
                      window.dispatchEvent(new CustomEvent('showLogin', {
                        detail: { callback: this.handleLoginComplete }
                      }));
                    }}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              {this.renderVideo()}
              {this.renderChat()}
              <div className="leaderboard-and-buttons-container">
                <div>
                  <h2>
                    {this.props.league 
                      ? `Host Leaderboard for League: ${this.props.league}`
                      : `Lobby: ${this.props.lobbyName}`
                    }
                  </h2>
                  {this.renderLeaderboard()}
                </div>
                {this.renderButtons()}
              </div>
            </>
          )}
          {/* Keep all dialogs */}
          {this.state.mode == 'customize-car' && this.renderCustomizeCarDialog()}
          {this.state.mode == 'customize-drivers' && this.renderCustomizeDriversDialog()}
          {this.state.mode == 'cars' && this.renderCarsDialog()}
          {this.state.mode == 'drivers' && this.renderDriversDialog()}
          {this.state.mode == 'passengers' && this.renderDriversDialog()}
          {this.state.mode == 'cosmetics' && this.renderCosmeticsDialog()}
          {this.state.alert != '' && this.renderAlertDialog()}
          {this.renderSeasonDialog()}
        </div>
      </div>
    );
  }
}


const LobbyWrapper = () => {
  const { lobbyName, league } = useParams<{ lobbyName: string; league?: string }>();
  const location = useLocation();

  console.log('LobbyWrapper - location:', location.pathname);
  console.log('LobbyWrapper - params:', { lobbyName, league });

  // Parse the URL to get the correct lobbyName and league
  let parsedLobbyName = lobbyName;
  let parsedLeague = league;

  if (lobbyName && lobbyName.includes('#')) {
    [parsedLobbyName, parsedLeague] = lobbyName.split('#');
  }

  console.log('Parsed params:', { parsedLobbyName, parsedLeague });

  if (!parsedLobbyName) {
    return <Navigate to="/" replace />;
  }

  return <LobbyPage lobbyName={parsedLobbyName} league={parsedLeague} />;
};

export default LobbyPage;
export { LobbyWrapper };