import React, { useState, useEffect } from 'react';
import { GameControls } from './GameControls';
import { CustomizeCarDialog } from './CustomizeCarDialog';
import { CustomizeDriversDialog } from './CustomizeDriversDialog';
import { AlertDialog } from './AlertDialog';
import { Network } from '../services/Network';
import { Account } from '../services/Account';
import { Database } from '../services/Database';
import './DriftPlay.css';

interface DriftPlayProps {
  driftData: {
    car: number;
    driver: number;
    passenger: number;
    crew: number;
    health: number;
    budget: number;
    racesRemaining: number;
  };
  onReturn: () => void;
}

export const DriftPlay: React.FC<DriftPlayProps> = ({ driftData, onReturn }) => {
  const [mode, setMode] = useState('play');
  const [alert, setAlert] = useState('');
  const [showChat, setShowChat] = useState(true);
  const [isOnline, setIsOnline] = useState(false);
  const [loadout, setLoadout] = useState({
    car: {
      id: Database.getDriftCarByServerId(driftData.car)?.id || 'default',
      booster: 'none',
      hood: 'none',
      door: 'none',
      emote: 'none',
      hat: 'none',
      horn: 'none',
      paint: 'none',
      ram: 'none',
      trail: 'none',
      underglow: 'none'
    },
    driver: {
      id: Database.getDriftDriverByServerId(driftData.driver)?.id || 'default',
      celebration: 'none',
      skin: 'none'
    },
    passenger: {
      id: Database.getDriftDriverByServerId(driftData.passenger)?.id || 'default',
      celebration: 'none',
      skin: 'none'
    }
  });

  // Split into two separate effects
  useEffect(() => {
    const connectToNetwork = async () => {
      if (Account.isLoggedIn()) {
        console.log('Connecting to network for drift mode');
        await Network.connect();
        Network.registerUser('drift', Account.getUserName());
      }
    };

    // Set up network message handler
    Network.onMessage = (message: any) => {
      let data = JSON.parse(message.data);
      console.log('Received network message:', data);
      // Handle any drift-specific messages here
    };

    connectToNetwork();

    return () => {
      Network.disconnect();
      Network.onMessage = () => {};
    };
  }, []); // Empty dependency array - only run once on mount

  // Separate effect for monitoring
  useEffect(() => {
    const monitorNetwork = setInterval(() => {
      const online = Network.isConnected();
      if (online !== isOnline) {
        setIsOnline(online);
      }
    }, 1000);

    return () => {
      clearInterval(monitorNetwork);
    };
  }, []); // Empty dependency array - only run once on mount

  const handleAction = (action: string) => {
    if (!Account.isLoggedIn()) {
      setAlert('Please login to play.');
      return;
    }

    if (!Network.isConnected()) {
      setAlert('Not connected to the network!');
      return;
    }

    // Use same game-action command as regular mode
    Network.sendEvent({
      id: 'game-action',
      channel: 'drift',
      action,
      user: {
        id: Account.getUserId(),
        name: Account.getUserName()
      }
    });
  };

  const handleJoinRace = () => {
    if (!Account.isLoggedIn()) {
      setAlert('Please login to play.');
      return;
    }

    if (!Network.isConnected()) {
      setAlert('Not connected to the network!');
      return;
    }

    Network.sendEvent({
      id: 'game-action',
      channel: 'drift',
      action: 'race',
      user: {
        id: Account.getUserId(),
        name: Account.getUserName()
      },
      data: {
        ...loadout,
        modifiers: {
          health: driftData.health
        }
      }
    });
  };

  const handleCustomizeSave = () => {
    setMode('play');
    // You might want to save the loadout to the server here
  };

  const handleCustomizeClose = () => {
    setMode('play');
  };

  const handlePreviousCar = () => {
    // Implement car selection logic
  };

  const handleNextCar = () => {
    // Implement car selection logic
  };

  const handleCarClick = () => {
    // Implement car click logic
  };

  const handleCosmetic = (collection: string, tag: string, type: string) => {
    // Implement cosmetic selection logic
  };

  const handlePreviousDriver = (type: string) => {
    // Implement driver selection logic
  };

  const handleNextDriver = (type: string) => {
    // Implement driver selection logic
  };

  const handleSelectDriver = (type: string) => {
    // Implement driver selection logic
  };

  const handleSwapDrivers = () => {
    setLoadout(prev => ({
      ...prev,
      driver: prev.passenger,
      passenger: prev.driver
    }));
  };

  return (
    <div className="drift-play">
      <div className="drift-play-content">
        <GameControls
          onAction={handleAction}
          onJoinRace={handleJoinRace}
          setMode={setMode}
          onOpenSeasonDialog={() => {}} // Not needed for drift mode
          onToggleChat={() => setShowChat(!showChat)}
          isOnline={isOnline}
          showChat={showChat}
        />

        {mode === 'customize-car' && (
          <CustomizeCarDialog
            loadout={loadout}
            onCustomizeClose={handleCustomizeClose}
            onCustomizeSave={handleCustomizeSave}
            onPreviousCar={handlePreviousCar}
            onNextCar={handleNextCar}
            onCarClick={handleCarClick}
            onCosmetic={handleCosmetic}
          />
        )}

        {mode === 'customize-drivers' && (
          <CustomizeDriversDialog
            loadout={loadout}
            onCustomizeClose={handleCustomizeClose}
            onCustomizeSave={handleCustomizeSave}
            onPreviousDriver={handlePreviousDriver}
            onNextDriver={handleNextDriver}
            onSelectDriver={handleSelectDriver}
            onCosmetic={handleCosmetic}
            onSwap={handleSwapDrivers}
          />
        )}

        {alert && (
          <AlertDialog
            message={alert}
            onClose={() => setAlert('')}
          />
        )}

        <button className="return-button" onClick={onReturn}>
          Return to Menu
        </button>
      </div>
    </div>
  );
}; 