import React from 'react';
import { Database } from '../services/Database';
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs';
import './DriverPanel.css';

interface DriverPanelProps {
  type: 'driver' | 'passenger';
  loadout: any;
  onPreviousDriver: () => void;
  onNextDriver: () => void;
  onSelectDriver: () => void;
  onCosmetic: (collection: string, tag: string, type: string) => void;
}

export const DriverPanel: React.FC<DriverPanelProps> = ({
  type,
  loadout,
  onPreviousDriver,
  onNextDriver,
  onSelectDriver,
  onCosmetic
}) => {
  const driverId = loadout[type].id;
  const driver = driverId !== 'random' ? Database.getDriver(driverId) : null;
  const driverIdx = driver ? Database.getDriverIndex(driverId) : Database.getTotalDrivers();
  const driverImage = driverId !== 'random' ? `/images/drivers/${driverId}.png` : '/images/random-driver.png';
  const driverName = driver ? driver.name : 'Random';

  const celebration = loadout[type].celebration !== 'none' 
    ? Database.getAsset(loadout[type].celebration).name 
    : 'None';

  return (
    <div className="driver-panel">
      <img 
        className="driver-image"
        src={driverImage} 
        onClick={onSelectDriver}
      />
      <div className="driver-name">{driverName}</div>
      <div className="driver-navigation">
        <div className="nav-button" onClick={onPreviousDriver}>
          <BsArrowLeftCircleFill />
        </div>
        <div className="driver-index">{driverIdx+1}/{Database.getTotalDrivers()+1}</div>
        <div className="nav-button" onClick={onNextDriver}>
          <BsArrowRightCircleFill />
        </div>
      </div>
      <div 
        className="celebration-button"
        onClick={() => onCosmetic('celebrations', '', type)}
      >
        <div className="celebration-label">Celebration</div>
        <div className="celebration-value">{celebration}</div>
      </div>
    </div>
  );
}; 