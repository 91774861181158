import React from 'react';
import { BsFillXCircleFill } from 'react-icons/bs';
import { CarPanel } from './CarPanel';
import { CarCosmeticsPanel } from './CarCosmeticsPanel';
import './CustomizeCarDialog.css';

interface CustomizeCarDialogProps {
  loadout: any;
  onCustomizeClose: () => void;
  onCustomizeSave: () => void;
  onPreviousCar: () => void;
  onNextCar: () => void;
  onCarClick: () => void;
  onCosmetic: (collection: string, tag: string, type: string) => void;
}

export const CustomizeCarDialog: React.FC<CustomizeCarDialogProps> = ({
  loadout,
  onCustomizeClose,
  onCustomizeSave,
  onPreviousCar,
  onNextCar,
  onCarClick,
  onCosmetic
}) => {
  return (
    <div className="dialog-cover">
      <div className="dialog-panel-centered customize-car-dialog">
        <div className="dialog-header">
          <div className="spacer"></div>
          <div>Customize Car</div>
          <div className="close-button" onClick={onCustomizeClose}>
            <BsFillXCircleFill />
          </div>
        </div>
        <div className="dialog-content">
          <CarPanel 
            loadout={loadout}
            onPreviousCar={onPreviousCar}
            onNextCar={onNextCar}
            onCarClick={onCarClick}
          />
          <CarCosmeticsPanel 
            loadout={loadout}
            onCosmetic={onCosmetic}
          />
          <button className="save-button" onClick={onCustomizeSave}>Save</button>
        </div>
      </div>
    </div>
  );
}; 