import { CrewMember } from '../interfaces/GameTypes';

export class Database {
  public static drivers = require('../../data/drivers.json');
  public static cars = require('../../data/cars.json');
  public static crew = require('../../data/crew.json');
  public static assets: any = {};
  public static collections = require('../../data/collections.json');

  protected static assetsByServerId: any = {};

  public static init() {
    Database.loadAssets();
  }

  protected static loadAssets() {
    let db = require('../../data/assets.json');
    let collections = Object.keys(db);
    for(let i = 0; i < collections.length; i++) {
      let collection = db[collections[i]];
      let assets = Object.keys(collection);
      for(let j = 0; j < assets.length; j++) {
        let assetId = assets[j];
        let asset = collection[assetId];
        asset.id = assetId;
        asset.collection = collections[i];
        Database.assets[assetId] = asset;
        Database.assetsByServerId[asset.server] = asset;
      }
    }
  }

  public static getDriverByServerId(id:number) {
    for(let i = 0; i < Database.drivers.length; i++) 
      if(Database.drivers[i].server == id)
        return Database.drivers[i];
    return null;
  }

  public static getCarByServerId(id:number) {
    for(let i = 0; i < Database.cars.length; i++) 
      if(Database.cars[i].server == id)
        return Database.cars[i];
    return null;
  }

  public static getCar(id: string | number) {
    const stringId = id.toString();
    for(let i = 0; i < Database.cars.length; i++) 
      if(Database.cars[i].id == stringId)
        return Database.cars[i];
    return null;
  }

  public static getCarIndex(id:string) {
    let idx = -1;
    for(let i = 0; i < Database.cars.length; i++) 
      if(Database.cars[i].id == id)
        idx = i;
    return idx;
  }

  public static getNextCar(id:string) {
    let idx = Database.getCarIndex(id);
    if(idx == -1)
      return null;

    idx++;
    if(idx > Database.cars.length-1)
      idx = 0;

    return Database.cars[idx];
  }

  public static getPreviousCar(id:string) {
    let idx = Database.getCarIndex(id);
    if(idx == -1)
      return null;
    
    idx--;
    if(idx < 0)
      idx = Database.cars.length-1;

    return Database.cars[idx];
  }

  public static getFirstCar() {
    return Database.cars[0];
  }

  public static getLastCar() {
    return Database.cars[Database.cars.length-1];
  }

  public static getTotalCars() {
    return Database.cars.length;
  }

  public static getRandomCar() {
    let r = Math.floor(Math.random() * Database.cars.length);
    return Database.cars[r];
  }

  public static getDriver(id: string | number) {
    const stringId = id.toString();
    for(let i = 0; i < Database.drivers.length; i++) 
      if(Database.drivers[i].id == stringId)
        return Database.drivers[i];
    return null;
  }

  public static getDriverIndex(id:string) {
    let idx = -1;
    for(let i = 0; i < Database.drivers.length; i++) 
      if(Database.drivers[i].id == id)
        idx = i;
    return idx;
  }

  public static getNextDriver(id:string) {
    let idx = Database.getDriverIndex(id);
    if(idx == -1)
      return null;

    idx++;
    if(idx > Database.drivers.length-1)
      idx = 0;

    return Database.drivers[idx];
  }

  public static getFirstDriver() {
    return Database.drivers[0];
  }

  public static getLastDriver() {
    return Database.drivers[Database.drivers.length-1];
  }

  public static getPreviousDriver(id:string) {
    let idx = Database.getDriverIndex(id);
    if(idx == -1)
      return null;
    
    idx--;
    if(idx < 0)
      idx = Database.drivers.length-1;

    return Database.drivers[idx];
  }

  public static getTotalDrivers() {
    return Database.drivers.length;
  }

  public static getDriverByName(name:string) {
    for(let i = 0; i < Database.drivers.length; i++) 
      if(Database.drivers[i].name == name)
        return Database.drivers[i];
    return null;
  }

  public static getRandomDriver() {
    let r = Math.floor(Math.random() * Database.drivers.length);
    return Database.drivers[r];
  }

  public static getCollection(id:string) {
    return Database.collections[id];
  }

  public static getAssets(collection:string = 'all') {
    let assets = [];
    let ids = Object.keys(Database.assets);
    for(let i = 0; i < ids.length; i++) {
      let asset = Database.assets[ids[i]];
      if(collection == 'all' || asset.collection == collection)
        assets.push(asset);
    }
    return assets;
  }

  public static getAsset(id:string): any {
    return Database.assets[id];
  }

  public static getAssetByServerId(id:string):any {
    return Database.assetsByServerId[id];
  }

  public static getCrewByServerId(id:number) {
    for(let i = 0; i < Database.crew.length; i++) 
      if(Database.crew[i].server == id)
        return Database.crew[i];
    return null;
  }

  public static getCrew(id: string | number) {
    const stringId = id.toString();
    for(let i = 0; i < Database.crew.length; i++) 
      if(Database.crew[i].id == stringId)
        return Database.crew[i];
    return null;
  }

  public static getDriftCarByServerId(id:number) {
    let car = Database.getCarByServerId(id);
    if(!car) {
      console.error(`Drift: Car with server ID ${id} not found`);
      return {
        id: '',
        name: 'Unknown Car',
        image: '/images/cars/default.png',
        weight: 0,
        health: 0,
        speed: 0,
        acceleration: 0,
        handling: 0
      };
    }
    return car;
  }

  public static getDriftDriverByServerId(id:number) {
    let driver = Database.getDriverByServerId(id);
    if(!driver) {
      console.error(`Drift: Driver with server ID ${id} not found`);
      return {
        id: '',
        name: 'Unknown Driver',
        image: '/images/drivers/default.png',
        driving: 0,
        boosting: 0,
        actions: 0,
        morale: 0,
        ramming: 0,
        shielding: 0,
        ability: 'No Ability'
      };
    }
    return driver;
  }

  static getDriftCrewByServerId(serverId: number): CrewMember {
    const crew = Database.getCrewByServerId(serverId);
    if (crew) {
      return {
        ...crew,
        image: `/images/crew/${crew.name.toLowerCase()}.jpg`
      };
    }

    // Return default if not found
    return {
      id: '0',
      name: 'Unknown Crew',
      ability: 'None',
      image: '/images/crew/default.jpg',
      server: 0
    };
  }

}