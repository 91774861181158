import React from 'react';
import { BsWechat, BsFillCaretLeftFill, BsFillCaretRightFill } from 'react-icons/bs';
import './GameControls.css';

interface GameControlsProps {
  onAction: (action: string) => void;
  onJoinRace: () => void;
  setMode: (mode: string) => void;
  onOpenSeasonDialog: () => void;
  onToggleChat: () => void;
  isOnline: boolean;
  showChat: boolean;
}

export const GameControls: React.FC<GameControlsProps> = ({
  onAction,
  onJoinRace,
  setMode,
  onOpenSeasonDialog,
  onToggleChat,
  isOnline,
  showChat
}) => {
  return (
    <div className="game-controls">
      <div className="game-controls-grid">
        <button onClick={() => onAction('boost')}>🚀<br/>Boost</button>
        <button onClick={() => onAction('ram')}>🔰<br/>Ram</button>
        <button onClick={onJoinRace}>🏁<br/>Join</button>
        <button onClick={() => setMode('customize-car')}>🚗<br/>Car</button>
        <button onClick={() => setMode('customize-drivers')}>🧑<br/>Drivers</button>
        
        <button onClick={() => onAction('brake')}>🛑<br/>Brake</button>
        <button onClick={() => onAction('shield')}>🛡️<br/>Shield</button>
        <button onClick={() => onAction('emote')}>😐<br/>Emote</button>
        <button onClick={() => onAction('horn')}>🎺<br/>Horn</button>
        <button onClick={onOpenSeasonDialog}>🏆<br/>Season</button>
      </div>

      <div className="network-indicator" style={{backgroundColor: isOnline ? 'green' : 'red'}} />
      
      <div className="chat-toggle" onClick={onToggleChat}>
        {showChat ? 
          <BsFillCaretRightFill style={{transform: 'translateY(-3px)'}} /> : 
          <BsFillCaretLeftFill style={{transform: 'translateY(-3px)'}} />
        }
        <BsWechat style={{fontSize: '1.5em'}} />
      </div>
    </div>
  );
}; 