import React from 'react';
import { Database } from '../services/Database';
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs';
import './CarPanel.css';

interface CarPanelProps {
  loadout: any;
  onPreviousCar: () => void;
  onNextCar: () => void;
  onCarClick: () => void;
}

export const CarPanel: React.FC<CarPanelProps> = ({
  loadout,
  onPreviousCar,
  onNextCar,
  onCarClick
}) => {
  const car = loadout.car.id !== 'random' ? Database.getCar(loadout.car.id) : null;
  const carIdx = car ? Database.getCarIndex(loadout.car.id) : Database.getTotalCars();
  const carImage = car ? `/images/cars/${car.id}.png` : '/images/random-car.png';
  const carName = car ? car.name : 'Random';

  const renderStatBar = (key: number, label: string, value: number) => {
    const pips = [];
    for(let i = 0; i < 5; i++) {
      const color = i < value ? 'orange' : '#ffffff10';
      pips.push(
        <div key={i} className="stat-pip" style={{backgroundColor: color}} />
      );
    }
    return (
      <div key={key} className="stat-bar">
        <div className="stat-label">{label}</div>
        <div className="stat-pips">{pips}</div>
      </div>
    );
  };

  return (
    <div className="car-panel">
      <img 
        className="car-image" 
        src={carImage} 
        onClick={onCarClick}
      />
      <div className="car-navigation">
        <div className="nav-button" onClick={onPreviousCar}>
          <BsArrowLeftCircleFill />
        </div>
        <div className="car-index">{carIdx+1}/{Database.getTotalCars()+1}</div>
        <div className="nav-button" onClick={onNextCar}>
          <BsArrowRightCircleFill />
        </div>
      </div>
      <div className="car-name">{carName}</div>
      <div className="car-stats">
        {car && (
          <>
            {renderStatBar(0, 'Weight', car.weight)}
            {renderStatBar(1, 'Health', car.health)}
            {renderStatBar(2, 'Speed', car.speed)}
            {renderStatBar(3, 'Accel', car.acceleration)}
            {renderStatBar(4, 'Handling', car.handling)}
          </>
        )}
      </div>
    </div>
  );
}; 