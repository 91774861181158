import React from 'react';
import { BsArrowClockwise, BsTrophy, BsLightning, BsShield, BsBullseye, BsExclamationTriangle, BsFlagFill } from 'react-icons/bs';
import './LeaderboardDisplay.css';

interface LeaderboardEntry {
  name: string;
  value: number;
}

interface LeaderboardData {
  host: string;
  races: number;
  points: LeaderboardEntry[];
  times: LeaderboardEntry[];
  damageDealt: LeaderboardEntry[];
  damageTaken: LeaderboardEntry[];
  actions: LeaderboardEntry[];
  dnf: LeaderboardEntry[];
  pointsPerRace?: Record<string, number[]>;
  [key: string]: any;
}

interface ContestEntry {
  name: string;
  races: number[];
  total: number;
}

interface LeaderboardDisplayProps {
  leaderboard: LeaderboardData | null;
  showContest: boolean;
  contestLength: number;
  contestData: ContestEntry[] | null;
  leaderboardIdx: number;
  onStatClick: (idx: number) => void;
  onToggleContest: (length: number) => void;
  onRefresh: () => void;
  title?: string;
  showLeagueDropdown?: boolean;
  leagueDropdown?: React.ReactNode;
  availableContestLengths?: number[];
}

export const LeaderboardDisplay: React.FC<LeaderboardDisplayProps> = ({
  leaderboard,
  showContest,
  contestLength,
  contestData,
  leaderboardIdx,
  onStatClick,
  onToggleContest,
  onRefresh,
  title,
  showLeagueDropdown = false,
  leagueDropdown,
  availableContestLengths = [5, 10, 15]
}) => {
  if (!leaderboard) {
    return null;
  }

  const leaderboardTypes = ['points', 'times', 'damageDealt', 'damageTaken', 'actions', 'dnf'] as const;
  const leaderboardTitles = ['Points', 'Fastest Race', 'Damage Dealt', 'Damage Taken', 'Actions', 'Did Not Finish'];
  const leaderboardIcons = [BsTrophy, BsLightning, BsShield, BsBullseye, BsExclamationTriangle, BsExclamationTriangle];
  const currentLeaderboard = leaderboard[leaderboardTypes[leaderboardIdx]] as LeaderboardEntry[];

  const renderContest = () => {
    if (!contestData) return null;

    return (
      <div style={{ overflowX: 'auto' }}>
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Name</th>
              {[...Array(contestLength)].map((_, idx) => (
                <th key={idx}>Race {idx + 1}</th>
              ))}
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {contestData.map((entry, idx) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{entry.name}</td>
                {[...Array(contestLength)].map((_, raceIdx) => (
                  <td key={raceIdx}>
                    {entry.races[raceIdx] !== undefined ? entry.races[raceIdx] : '-'}
                  </td>
                ))}
                <td>{entry.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="leaderboard-container">
      <div className="leaderboard-header">
        <h2 className="leaderboard-title">{title || `${leaderboard.host}'s Leaderboard`}</h2>
        <span className="leaderboard-subtitle">Total Races: {leaderboard.races}</span>
      </div>

      <div className="leaderboard-tabs">
        {showLeagueDropdown && leagueDropdown}
        
        {leaderboardTitles.map((title, idx) => {
          const Icon = leaderboardIcons[idx];
          return (
            <button
              key={idx}
              onClick={() => onStatClick(idx)}
              className={`tab-button ${leaderboardIdx === idx && !showContest ? 'active' : ''}`}
            >
              <Icon className="tab-icon" /> {title}
            </button>
          );
        })}

        {availableContestLengths.map(length => (
          <button
            key={length}
            onClick={() => onToggleContest(length)}
            className={`tab-button ${showContest && contestLength === length ? 'active' : ''}`}
          >
            <BsFlagFill className="tab-icon" /> {length} Races
          </button>
        ))}
      </div>

      {showContest ? renderContest() : (
        <div className="leaderboard-table-container">
          <table className="leaderboard-table">
            <tbody>
              {currentLeaderboard.slice(0, 10).map((entry: LeaderboardEntry, idx: number) => (
                <tr key={idx}>
                  <td className="rank-cell">
                    <span className={`rank-badge ${idx < 3 ? `rank-${idx + 1}` : ''}`}>
                      {idx + 1}
                    </span>
                  </td>
                  <td className="name-cell">{entry.name}</td>
                  <td className="value-cell">
                    {entry.value.toFixed(leaderboardIdx === 1 ? 2 : 0)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="leaderboard-footer">
        <button onClick={onRefresh} className="refresh-button">
          <BsArrowClockwise className="refresh-icon" /> Refresh
        </button>
      </div>
    </div>
  );
}; 