import React, { useState, useEffect } from 'react';
import { Database } from '../services/Database';
import './DriftMainMenu.css';
import { Car, Driver, CrewMember, Car as CarType } from '../interfaces/GameTypes';
import { PlayFab } from '../services/PlayFab';
import { LeaderboardDisplay } from './LeaderboardDisplay';
import { Races } from '../services/Races';
import { DriftPlay } from './DriftPlay';
import { useParams } from 'react-router-dom';

// Define interface for car data
interface CarData {
  id: string;
  name: string;
  health: number;
  speed: number;
  acceleration: number;
  handling: number;
  weight: number;
}

interface DriftData {
  car: string;
  driver: string;
  passenger: string;
  crew: string;
  budget: number;
  racesRemaining: number;
}

interface DriftMainMenuProps {
  driftData: {
    car: number;
    driver: number;
    passenger: number;
    crew: number;
    health: number;
    budget: number;
    racesRemaining: number;
    leagueId?: string;
  };
  onUpgradeCar: () => void;
  onRecruitCrew: () => void;
  onRevive: () => void;
}

export const DriftMainMenu: React.FC<DriftMainMenuProps> = ({ 
  driftData, 
  onUpgradeCar, 
  onRecruitCrew,
  onRevive
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [leaderboard, setLeaderboard] = useState<any>(null);
  const [leaderboardIdx, setLeaderboardIdx] = useState(0);
  const [showContest, setShowContest] = useState(true);
  const [contestLength, setContestLength] = useState(5);
  const [contestData, setContestData] = useState<any>(null);
  const [showLeaderboard, setShowLeaderboard] = useState(true);
  const { league } = useParams<{ league: string }>();

  const car = Database.getDriftCarByServerId(driftData.car) || {
    id: '0',
    name: 'Unknown Car',
    image: '/images/cars/default.png',
    weight: 0,
    health: 0,
    speed: 0,
    acceleration: 0,
    handling: 0
  };

  const maxCarHealth = car.health * 100;

  const driver = Database.getDriftDriverByServerId(driftData.driver) || {
    id: '0',
    name: 'Unknown Driver',
    image: '/images/drivers/default.png',
    driving: 0,
    boosting: 0,
    actions: 0,
    morale: 0,
    ramming: 0,
    shielding: 0,
    ability: 'None'
  };

  const passenger = Database.getDriftDriverByServerId(driftData.passenger) || {
    id: '0',
    name: 'Unknown Passenger',
    image: '/images/drivers/default.png',
    driving: 0,
    boosting: 0,
    actions: 0,
    morale: 0,
    ramming: 0,
    shielding: 0,
    ability: 'None'
  };

  const crew = Database.getDriftCrewByServerId(driftData.crew) || {
    id: '0',
    name: 'Unknown Crew',
    ability: 'None',
    image: '/images/crew/default.jpg'
  };

  const canRevive = driftData.budget >= 1000 && driftData.health < car.health;

  useEffect(() => {
    getDriftLeaderboard();
  }, []);

  const getDriftLeaderboard = async () => {
    try {
      const targetLeague = driftData.leagueId || 'drift01';
      const host = `drift#${targetLeague}`;
      
      const response = await Races.getHostLeaderboard(host);
      if (response.success) {
        processLeaderboard(response.body.leaderboard);
      } else {
        console.log(`League ${targetLeague} not found`);
        setLeaderboard(null);
      }
    } catch (error) {
      console.error("Error fetching drift leaderboard:", error);
    }
  };

  const processLeaderboard = (data: any) => {
    let actions = [];
    let times = [];
    for (let i = 0; i < data.users.length; i++) {
      actions.push(data.used.boosts[i] + data.used.brakes[i] + data.used.rams[i] + data.used.shields[i])
      times.push(+(data.times[i] / 1000).toFixed(2));
    }

    const processedLeaderboard = {
      host: 'Drift Mode',
      races: data.races,
      points: createLeaderboard(data.users, data.points, true),
      times: createLeaderboard(data.users, times, false),
      damageDealt: createLeaderboard(data.users, data.damage.dealt, true),
      damageTaken: createLeaderboard(data.users, data.damage.taken, true),
      actions: createLeaderboard(data.users, actions, true),
      dnf: createLeaderboard(data.users, data.dnf, true),
      pointsPerRace: data.points_per_race || {},
    };

    setLeaderboard(processedLeaderboard);
    prepareContestData(data.points_per_race || {}, 5);
  };

  const createLeaderboard = (users: string[], values: number[], descending: boolean) => {
    let entries = [];
    for (let i = 0; i < users.length; i++) {
      if (values[i] > 0) {
        entries.push({ name: users[i], value: values[i] });
      }
    }

    entries.sort((a, b) => {
      if (a.value > b.value) return descending ? -1 : 1;
      if (a.value < b.value) return descending ? 1 : -1;
      return a.name.localeCompare(b.name);
    });

    return entries;
  };

  const prepareContestData = (pointsPerRace: Record<string, number[]>, length: number = contestLength) => {
    if (!pointsPerRace || Object.keys(pointsPerRace).length === 0) {
      setContestData([]);
      return;
    }

    const data = Object.entries(pointsPerRace).map(([name, races]) => {
      const contestRaces = races.slice(0, length);
      const total = contestRaces.reduce((sum, points) => sum + points, 0);
      return { name, races: contestRaces, total };
    });

    data.sort((a, b) => b.total - a.total);
    setContestData(data);
  };

  const handleToggleContest = (length: number) => {
    const newShowContest = !(showContest && contestLength === length);
    setShowContest(newShowContest);
    setContestLength(length);
    if (newShowContest) {
      prepareContestData(leaderboard?.pointsPerRace || {});
    }
  };

  const handleStatClick = (idx: number) => {
    setLeaderboardIdx(idx);
    setShowContest(false);
  };

  const handleRevive = async () => {
    try {
      const result = await PlayFab.executeCloudScript('reviveDriftCar');
      
      console.log('Revive response:', result);
      
      if (!result.data) {
        console.error('No data received from cloud script');
        return;
      }

      if (result.data.success) {
        console.log('Revive successful, new data:', result.data.updatedData);
        onRevive();
      } else {
        const errorMessage = result.data.error || 'Unknown error';
        console.error('Failed to revive:', errorMessage);
        // You could add a toast or error message here
      }
    } catch (error) {
      console.error('Error executing cloud script:', error);
      // You could add a toast or error message here
    }
  };

  const handleRepair = async () => {
    try {
      const result = await PlayFab.executeCloudScript('repairDriftCar');
      
      console.log('Repair response:', result);
      
      if (!result.data) {
        console.error('No data received from cloud script');
        return;
      }

      if (result.data.success) {
        console.log('Repair successful:', result.data);
        onRevive(); // Use the same callback as revive to refresh the data
      } else {
        const errorMessage = result.data.error || 'Unknown error';
        console.error('Failed to repair:', errorMessage);
        // You could add a toast or error message here
      }
    } catch (error) {
      console.error('Error executing cloud script:', error);
      // You could add a toast or error message here
    }
  };

  // Calculate repair cost if needed
  const healthNeeded = maxCarHealth - driftData.health;
  const repairUnits = Math.ceil(healthNeeded / 100);
  const repairCost = repairUnits * 100;
  const canRepair = driftData.budget >= repairCost && driftData.health < maxCarHealth;

  const getHealthColor = (percentage: number): string => {
    if (percentage > 0.6) return '#4CAF50';  // Green
    if (percentage > 0.3) return '#FFC107';  // Yellow
    return '#F44336';  // Red
  };

  if (isPlaying) {
    return (
      <DriftPlay 
        driftData={driftData}
        onReturn={() => setIsPlaying(false)}
      />
    );
  }

  return (
    <div className="drift-main-menu">
      <div className="drift-status">
        <h2>Drift Team Status</h2>
        <div className="drift-stats">
          <div className="stat-item">
            <div className="stat-label">💰 Budget</div>
            <div className="stat-value">${driftData.budget.toLocaleString()}</div>
          </div>

          <div className="stat-item">
            <div className="stat-label">❤️ Health</div>
            <div className="health-bar-container">
              <div 
                className="health-bar" 
                style={{
                  width: `${(driftData.health / maxCarHealth) * 100}%`,
                  backgroundColor: getHealthColor(driftData.health / maxCarHealth)
                }}
              />
              <span className="health-text">
                {driftData.health} / {maxCarHealth}
              </span>
            </div>
          </div>

          <div className="stat-item">
            <div className="stat-label">🏁 Races Remaining</div>
            <div className="stat-value">{driftData.racesRemaining} / 5 </div>
          </div>
        </div>
      </div>

      <div className="drift-actions">
        <button className="primary-button" onClick={() => setIsPlaying(true)}>
          Start Race
        </button>
        <button className="secondary-button" onClick={onUpgradeCar}>
          Upgrade Car
        </button>
        <button className="secondary-button" onClick={onRecruitCrew}>
          Recruit Crew
        </button>
        <button className="secondary-button" onClick={() => setShowLeaderboard(!showLeaderboard)}>
          {showLeaderboard ? 'Hide Standings' : 'Show Standings'}
        </button>
        {canRepair && (
          <button 
            className="secondary-button repair-button" 
            onClick={handleRepair}
          >
            Repair (${repairCost})
          </button>
        )}
        {canRevive && (
          <button 
            className="secondary-button" 
            onClick={handleRevive}
          >
            Revive ($1000)
          </button>
        )}
      </div>

      <div className="drift-team-display">
        <div className="drift-team-member">
          <h3>Car</h3>
          <img src={car.image || `/images/cars/${car.id}.png`} alt={car.name} />
          <div>{car.name}</div>
        </div>

        <div className="drift-team-member">
          <h3>Driver</h3>
          <img src={driver.image || `/images/drivers/${driver.id}.png`} alt={driver.name} />
          <div>{driver.name}</div>
        </div>

        <div className="drift-team-member">
          <h3>Passenger</h3>
          <img src={passenger.image || `/images/drivers/${passenger.id}.png`} alt={passenger.name} />
          <div>{passenger.name}</div>
        </div>

        <div className="drift-team-member">
          <h3>Pit Crew</h3>
          <img 
            src={crew.image || `/images/crew/${crew.id}.png`} 
            alt={crew.name}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.onerror = null; // Prevent infinite loop
              target.src = '/images/crew/default.png';
            }}
          />
          <div>{crew.name}</div>
        </div>
      </div>

      {showLeaderboard && (
        <LeaderboardDisplay
          leaderboard={leaderboard}
          showContest={showContest}
          contestLength={contestLength}
          contestData={contestData}
          leaderboardIdx={leaderboardIdx}
          onStatClick={handleStatClick}
          onToggleContest={handleToggleContest}
          onRefresh={getDriftLeaderboard}
          title="Drift Mode Leaderboard"
          availableContestLengths={[5]}
        />
      )}
    </div>
  );
}; 