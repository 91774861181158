import React from 'react';
import { BsFillXCircleFill, BsArrowsExpandVertical } from 'react-icons/bs';
import { DriverPanel } from './DriverPanel';
import './CustomizeDriversDialog.css';

interface CustomizeDriversDialogProps {
  loadout: any;
  onCustomizeClose: () => void;
  onCustomizeSave: () => void;
  onPreviousDriver: (type: string) => void;
  onNextDriver: (type: string) => void;
  onSelectDriver: (type: string) => void;
  onCosmetic: (collection: string, tag: string, type: string) => void;
  onSwap: () => void;
}

export const CustomizeDriversDialog: React.FC<CustomizeDriversDialogProps> = ({
  loadout,
  onCustomizeClose,
  onCustomizeSave,
  onPreviousDriver,
  onNextDriver,
  onSelectDriver,
  onCosmetic,
  onSwap
}) => {
  return (
    <div className="dialog-cover">
      <div className="dialog-panel-centered customize-drivers-dialog">
        <div className="dialog-header">
          <div className="spacer"></div>
          <div>Customize Driver & Passenger</div>
          <div className="close-button" onClick={onCustomizeClose}>
            <BsFillXCircleFill />
          </div>
        </div>
        <div className="dialog-content">
          <div className="drivers-container">
            <DriverPanel
              type="driver"
              loadout={loadout}
              onPreviousDriver={() => onPreviousDriver('driver')}
              onNextDriver={() => onNextDriver('driver')}
              onSelectDriver={() => onSelectDriver('driver')}
              onCosmetic={onCosmetic}
            />
            <div className="swap-button" onClick={onSwap}>
              <BsArrowsExpandVertical />
            </div>
            <DriverPanel
              type="passenger"
              loadout={loadout}
              onPreviousDriver={() => onPreviousDriver('passenger')}
              onNextDriver={() => onNextDriver('passenger')}
              onSelectDriver={() => onSelectDriver('passenger')}
              onCosmetic={onCosmetic}
            />
          </div>
          <button className="save-button" onClick={onCustomizeSave}>Save</button>
        </div>
      </div>
    </div>
  );
}; 