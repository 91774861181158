import React from 'react';
import { ApolloClient, InMemoryCache, createHttpLink, gql } from '@apollo/client';
import { Database } from './services/Database';
import { Account } from './services/Account';
import { Navigate } from 'react-router-dom';
import { PlayFab } from './services/PlayFab';

import assetsJson from '../data/assets.json';

interface AssetItem {
  name: string;
  server: string;
  price?: {
    currency: string;
    amount: number;
  };
  image?: string;
  tags?: string[];
}

interface AssetsData {
  boosters: Record<string, AssetItem>;
  cars: Record<string, AssetItem>;
  drivers: Record<string, AssetItem>;
  trails: Record<string, AssetItem>;
  underglows: Record<string, AssetItem>;
  paint: Record<string, AssetItem>;
  horns: Record<string, AssetItem>;
  decals: Record<string, AssetItem>;
  emotes: Record<string, AssetItem>;
  hats: Record<string, AssetItem>;
  celebrations: Record<string, AssetItem>;
  skins: Record<string, AssetItem>;
}

const assets = assetsJson as unknown as AssetsData;

const client = new ApolloClient({
  link: createHttpLink({
    uri: 'https://071262-08.myshopify.com/api/2023-01/graphql.json',
    headers: {
      'X-Shopify-Storefront-Access-Token': '2bd59775445dff00cc90f68a76fc1fa1'
    }
  }),
  cache: new InMemoryCache()
});

interface Product {
  id: string;
  title: string;
  description: string;
  productType: string;
  tags: string[];
  images: {
    edges: Array<{
      node: {
        src: string;
      }
    }>
  };
  variants: {
    edges: Array<{
      node: {
        id: string;
        sku: string;
        price: {
          amount: string;
          currencyCode: string;
        }
      }
    }>
  };
}

interface ShopItem {
  id: string;
  type: string;
  rarity: 'LEGENDARY' | 'EPIC' | 'RARE' | 'COMMON';
  name: string;
  assetId: string;
  price?: {
    currency: string;
    amount: number;
  };
  purchased?: boolean;
}

interface StorePageState {
  loading: boolean;
  discountCode: string;
  products: Product[];
  allTags: string[];
  selectedTags: string[];
  rotatingItems: string[];
  rotationExpiresAt: number | null;
  shopData: {
    items: ShopItem[];
    expiresAt: number;
  } | null;
  currencies: { [key: string]: number };
  catalogItems: any[];
}

interface QueryResult {
  collections: {
    edges: Array<{
      node: {
        products: {
          edges: Array<{
            node: Product
          }>
        }
      }
    }>
  }
}

function getItemDetails(serverId: string) {
  if (!serverId) return null;
  
  const categories = ['boosters', 'cars', 'drivers', 'trails', 'underglows', 'paint', 'horns', 'decals', 'emotes', 'hats', 'celebrations', 'skins'] as const;
  type Category = typeof categories[number];
  
  for (const category of categories) {
    try {
      const items = assets[category as Category];
      if (!items) continue;
      
      // Find the item where server property matches our serverId
      const assetId = Object.keys(items).find(key => items[key].server === serverId);
      if (assetId) {
        const item = items[assetId];
        return { ...item, id: assetId, category };
      }
    } catch (error) {
      console.error(`Error accessing category ${category}:`, error);
      continue;
    }
  }
  return null;
}

class StorePage extends React.Component<{}, StorePageState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: false,
      discountCode: '',
      products: [],
      allTags: [],
      selectedTags: [],
      rotatingItems: [],
      rotationExpiresAt: null,
      shopData: null,
      currencies: {},
      catalogItems: []
    }
  }

  componentDidMount(): void {
    this.fetchProducts();
    this.checkExistingRotation();
    this.fetchCurrencies();
  }

  async fetchProducts() {
    this.setState({ loading: true });
    try {
      const result = await client.query<{
        collections: any; data: QueryResult 
      }>({
        query: gql`
          {
            collections(first: 1, query: "title:Nova Rally Site Store") {
              edges {
                node {
                  products(first: 250) {
                    edges {
                      node {
                        id
                        title
                        description
                        productType
                        tags
                        images(first: 1) {
                          edges {
                            node {
                              src
                            }
                          }
                        }
                        variants(first: 1) {
                          edges {
                            node {
                              id
                              sku
                              price {
                                amount
                                currencyCode
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `
      });

      const collections = result.data?.collections?.edges || [];
      const products = collections[0]?.node?.products?.edges?.map((edge: { node: Product }) => edge.node) || [];
      
      console.log(`Fetched ${products.length} products`);

      const allTags = Array.from(new Set(products.flatMap((product: Product) => product.tags || [])));
      this.setState({ products, allTags: allTags as string[] });
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    this.setState({ loading: false });
  }

  handleDiscountCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ discountCode: event.target.value });
  }

  handleTagToggle = (tag: string) => {
    this.setState(prevState => ({
      selectedTags: prevState.selectedTags.includes(tag) ? [] : [tag]
    }));
  }

  async onPurchase(variantId: string) {
    this.setState({loading: true});
    
    try {
      const result = await client.mutate({
        mutation: gql`
          mutation createCheckout($input: CheckoutCreateInput!) {
            checkoutCreate(input: $input) {
              checkout {
                id
                webUrl
              }
              checkoutUserErrors {
                code
                field
                message
              }
            }
          }
        `,
        variables: {
          input: {
            lineItems: [{ variantId, quantity: 1 }],
            customAttributes: [
              {
                key: "PlayFabId",
                value: Account.getUserId()
              }
            ]
          }
        }
      });

      const checkout = result.data.checkoutCreate.checkout;

      if (this.state.discountCode) {
        const discountResult = await client.mutate({
          mutation: gql`
            mutation checkoutDiscountCodeApplyV2($checkoutId: ID!, $discountCode: String!) {
              checkoutDiscountCodeApplyV2(checkoutId: $checkoutId, discountCode: $discountCode) {
                checkout {
                  id
                  webUrl
                }
                checkoutUserErrors {
                  code
                  field
                  message
                }
              }
            }
          `,
          variables: {
            checkoutId: checkout.id,
            discountCode: this.state.discountCode
          }
        });

        if (discountResult.data.checkoutDiscountCodeApplyV2.checkoutUserErrors.length > 0) {
          console.error('Error applying discount:', discountResult.data.checkoutDiscountCodeApplyV2.checkoutUserErrors);
        }
      }

      window.location.href = checkout.webUrl;
    } catch (error) {
      console.error('Error creating checkout:', error);
    }

    this.setState({loading: false});
  }

  renderProduct(product: Product) {
    const variant = product.variants.edges[0]?.node;
    const image = product.images.edges[0]?.node.src;
    const sku = variant?.sku;
    
    if (!sku) return null;
    
    const isRotatingItem = this.state.rotatingItems.includes(sku);
    
    const rarityColors: Record<ShopItem['rarity'], string> = {
      LEGENDARY: '#FFD700',
      EPIC: '#A335EE',
      RARE: '#0070DD',
      COMMON: '#FFFFFF'
    };

    if (isRotatingItem) {
      const rotatingItemInfo = this.state.shopData?.items.find(i => i.id === sku);
      if (!rotatingItemInfo || !this.state.shopData) return null;

      const catalogItem = this.state.catalogItems.find(item => item.Id === rotatingItemInfo.id);
      // Use catalogItem as needed

      const category = rotatingItemInfo.type;
      if (!isValidCategory(category)) {
        console.warn(`Invalid category: ${category}`);
        return null;
      }

      const assetId = rotatingItemInfo.assetId;
      const assetInfo = assets[category][assetId];

      if (!assetInfo) {
        console.warn(`Missing asset info for ${category}/${assetId}`);
        return null;
      }

      const imagePath = assetInfo.image 
        ? `/images/assets/${category}/${assetInfo.image}.png`
        : `/images/assets/${category}/${assetId}.png`;

      return (
        <div key={rotatingItemInfo.id} className="flex-column" style={{
          alignItems: 'center', 
          margin: '20px', 
          width: '250px',
          position: 'relative'
        }}>
          <div style={{ position: 'relative' }}>
            <img 
              src={imagePath}
              style={{
                width: '200px', 
                height: '200px', 
                objectFit: 'cover', 
                borderRadius: '10px'
              }} 
              alt={assetInfo.name} 
            />
            <div style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'rgba(0,0,0,0.8)',
              padding: '5px 10px',
              borderRadius: '10px',
              color: rarityColors[rotatingItemInfo.rarity],
              fontWeight: 'bold',
              fontSize: '0.8em',
              textTransform: 'uppercase',
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
            }}>
              {rotatingItemInfo.rarity}
            </div>
          </div>
          <div style={{fontWeight: 'bold', marginTop: '10px'}}>{rotatingItemInfo.name}</div>
          {rotatingItemInfo.price && (
            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
              <img 
                src={`/images/currencies/${rotatingItemInfo.price.currency}.png`}
                style={{width: '20px', height: '20px'}}
                alt={rotatingItemInfo.price.currency}
              />
              <span>{rotatingItemInfo.price.amount}</span>
            </div>
          )}
          <button 
            onClick={() => this.handleRotatingItemPurchase(sku)}
            disabled={rotatingItemInfo.purchased}
            style={{
              background: rotatingItemInfo.purchased 
                ? '#666' 
                : 'linear-gradient(197.78deg, #EF8F49 -12.54%, #F1BD42 104.72%)',
              border: 'none',
              color: 'white',
              padding: '10px 20px',
              borderRadius: '15px',
              cursor: rotatingItemInfo.purchased ? 'default' : 'pointer',
              marginTop: '10px'
            }}
          >
            {rotatingItemInfo.purchased ? 'Purchased' : 'Purchase'}
          </button>
        </div>
      );
    }

    return (
      <div key={product.id} className="flex-column" style={{
        alignItems: 'center', 
        margin: '20px', 
        width: '250px',
        position: 'relative'
      }}>
        {image && (
          <div style={{ position: 'relative' }}>
            <img 
              src={image} 
              style={{
                width: '200px', 
                height: '200px', 
                objectFit: 'cover', 
                borderRadius: '10px'
              }} 
              alt={product.title} 
            />
          </div>
        )}
        <div style={{fontWeight: 'bold', marginTop: '10px'}}>{product.title}</div>
        <div>{this.formatPrice(variant.price)}</div>
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', margin: '10px 0'}}>
          {product.tags.map(tag => (
            <span key={tag} style={{
              background: '#3856B0',
              color: 'white',
              borderRadius: '15px',
              padding: '5px 10px',
              margin: '2px',
              fontSize: '0.8em'
            }}>
              {tag}
            </span>
          ))}
        </div>
        <button onClick={() => this.onPurchase(variant.id)} style={{
          background: 'linear-gradient(197.78deg, #EF8F49 -12.54%, #F1BD42 104.72%)',
          border: 'none',
          color: 'white',
          padding: '10px 20px',
          textAlign: 'center',
          textDecoration: 'none',
          display: 'inline-block',
          fontSize: '16px',
          margin: '4px 2px',
          cursor: 'pointer',
          borderRadius: '15px',
          minHeight: '40px',
          minWidth: '60px'
        }}>
          Buy Now
        </button>
      </div>
    )
  }

  formatPrice(price: { amount: string; currencyCode: string }): string {
    const amount = parseFloat(price.amount);
    return `$${amount.toFixed(2)} ${price.currencyCode}`;
  }

  renderLoading() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel-centered" style={{maxWidth: '200px'}}>
          Loading...
        </div>
      </div>
    )
  }

  async checkExistingRotation() {
    const result = await PlayFab.executeCloudScript('getRotatingShop');
    if (result.data?.data) {
      const shopData = result.data.data;
      
      // Fetch catalog data once
      const catalogItems = await PlayFab.getCatalogItems(shopData.items.map((item: ShopItem) => item.id));
      
      // Map prices to shop items
      const itemsWithPrices = shopData.items.map((shopItem: ShopItem) => {
        const catalogItem = catalogItems.find(ci => ci.Id === shopItem.id);
        if (catalogItem && catalogItem.PriceOptions.Prices.length > 0) {
          const priceInfo = catalogItem.PriceOptions.Prices[0].Amounts[0];
          shopItem.price = {
            currency: priceInfo.ItemId,
            amount: priceInfo.Amount
          };
        }
        return shopItem;
      });

      this.setState({
        shopData: { ...shopData, items: itemsWithPrices },
        rotationExpiresAt: shopData.expiresAt,
        rotatingItems: itemsWithPrices.map((item: ShopItem) => item.id),
        catalogItems  // Store in state
      });
    }
  }

  handleRevealItems = async () => {
    const now = Date.now();
    if (this.state.rotationExpiresAt && now < this.state.rotationExpiresAt) {
      console.log('Cannot reveal: current rotation still active');
      return;
    }

    this.setState({ loading: true });
    try {
      console.log('Calling revealRotatingItems...');
      const result = await PlayFab.executeCloudScript('revealRotatingItems');
      console.log('Reveal result:', result);
      
      if (result.data?.data) {
        console.log('Reveal successful, checking rotating items...');
        await this.checkExistingRotation();
        console.log('Current state after update:', this.state);
      }
    } catch (error) {
      console.error('Error revealing items:', error);
    }
    this.setState({ loading: false });
  };

  async getRarityInfo(productId: string): Promise<ShopItem | null> {
    const result = await PlayFab.executeCloudScript('getRotatingShop');
    const shop = result.data?.FunctionResult?.data;
    if (shop?.items) {
      return shop.items.find((item: ShopItem) => item.id === productId) || null;
    }
    return null;
  }

  async fetchCurrencies() {
    try {
      const currencies = await Account.getCurrencies();
      this.setState({ currencies });
    } catch (error) {
      console.error('Error in fetchCurrencies:', error);
    }
  }

  async handleRotatingItemPurchase(itemId: string) {
    this.setState({ loading: true });
    try {
      const result = await PlayFab.executeCloudScript('purchaseRotatingItem', {
        itemId: itemId
      });
      
      if (result.data?.success) {
        await this.fetchCurrencies(); // Refresh currencies
        await this.checkExistingRotation(); // Refresh shop data
      } else {
        // Use a nicer error message
        const errorMessage = result.data?.error || 'Purchase failed';
        // You could create a custom alert component here, but for now:
        alert(errorMessage);
      }
    } catch (error: any) {
      console.error('Error purchasing rotating item:', error);
      alert(error?.message || 'Failed to purchase item');
    }
    this.setState({ loading: false });
  }

  render() {
    if(!Account.isDeveloperLoggedIn())
      return <Navigate to="/" replace />

    const filteredProducts = this.state.selectedTags.length > 0
      ? this.state.products.filter(product => 
          product.tags.includes(this.state.selectedTags[0])
        )
      : this.state.products;

    const canReveal = !this.state.rotationExpiresAt || Date.now() > this.state.rotationExpiresAt;
    const timeRemaining = this.state.rotationExpiresAt ? this.state.rotationExpiresAt - Date.now() : 0;
    const hoursRemaining = Math.max(0, Math.floor(timeRemaining / (1000 * 60 * 60)));
    const minutesRemaining = Math.max(0, Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60)));

    const rotatingProducts = this.state.products.filter(p => {
      const sku = p.variants.edges[0]?.node.sku;
      return sku && this.state.rotatingItems.includes(sku);
    });

    return (
      <div className="page">
        <div className="flex-column" style={{maxWidth: '1200px', margin: 'auto'}}>
          <div style={{
            background: '#1E1E1E',
            borderRadius: '15px',
            padding: '20px',
            margin: '20px 0',
            textAlign: 'center'
          }}>
            <h2 style={{color: 'white', marginBottom: '15px'}}>Daily Rotating Items</h2>
            
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '20px',
              marginBottom: '15px'
            }}>
              {Object.entries(this.state.currencies).map(([currencyId, amount]) => (
                <div key={currencyId} style={{
                  color: 'white',
                  background: 'rgba(0,0,0,0.3)',
                  padding: '5px 15px',
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px'
                }}>
                  <img 
                    src={`/images/currencies/${currencyId}.png`} 
                    alt={currencyId}
                    style={{width: '20px', height: '20px'}}
                  />
                  <span>{amount}</span>
                </div>
              ))}
            </div>

            <button 
              onClick={this.handleRevealItems}
              disabled={!canReveal}
              style={{
                background: canReveal ? 'linear-gradient(197.78deg, #EF8F49 -12.54%, #F1BD42 104.72%)' : '#666',
                padding: '10px 20px',
                borderRadius: '15px',
                border: 'none',
                color: 'white',
                cursor: canReveal ? 'pointer' : 'not-allowed',
                marginBottom: '15px'
              }}
            >
              Reveal Daily Items
            </button>

            {this.state.rotationExpiresAt && (
              <div style={{color: 'white', marginBottom: '15px'}}>
                Time remaining: {hoursRemaining}h {minutesRemaining}m
              </div>
            )}

            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '20px'
            }}>
              {this.state.rotatingItems.length > 0 ? (
                this.state.products
                  .filter(p => {
                    const sku = p.variants.edges[0]?.node.sku;
                    return sku && this.state.rotatingItems.includes(sku);
                  })
                  .map(product => this.renderProduct(product))
              ) : (
                <div style={{color: '#888', fontSize: '1.1em'}}>
                  Click reveal to see today's special items!
                </div>
              )}
            </div>
          </div>

          <div>
            <input
              type="text"
              placeholder="Enter discount code"
              value={this.state.discountCode}
              onChange={this.handleDiscountCodeChange}
              style={{margin: '20px 0', padding: '10px', width: '200px'}}
            />
          </div>
          <div style={{margin: '20px 0', display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
  {this.state.allTags.map(tag => (
    <button
      key={tag}
      onClick={() => this.handleTagToggle(tag)}
      style={{
        background: this.state.selectedTags.includes(tag) ? 'linear-gradient(197.78deg, #EF8F49 -12.54%, #F1BD42 104.72%)' : '#3856B0',
        color: 'white',
        border: 'none',
        borderRadius: '15px',
        padding: '5px 10px',
        margin: '5px',
        cursor: 'pointer',
        fontSize: '0.8em'
      }}
    >
      {tag}
    </button>
  ))}
</div>
          <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            {filteredProducts.map(product => this.renderProduct(product))}
          </div>
        </div>
        {this.state.loading && this.renderLoading()}
      </div>
    )
  }
}

type ValidCategory = keyof AssetsData;

function isValidCategory(category: string): category is ValidCategory {
  return category in assets;
}

export default StorePage;